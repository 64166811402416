import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";
import SelectStandalone from "./SelectStandalone";

export default function Select({
  label,
  className = "marginT10",
  mandatory,
  ...props
}) {
  const [field, meta] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <SelectStandalone
      label={label}
      mandatory={mandatory}
      className={className}
      isInvalid={meta.error && meta.touched && !props.disabled}
      error={meta.error}
      {...field}
      {...props}
    />
  );
}
