import ToggleSwitch from "../UI/ToggleSwitch";
import moment from "moment";
import CardToggle from "../UI/CardToggle";

export default function Action({
  action,
  checked,
  onToggle,
  tenantAutoAction,
  disabled,
}) {
  return (
    <CardToggle
      padding={"md"}
      arrowBefore
      title={
        <>
          <div className={"marginRAuto"}>{action.nom}</div>
          <ToggleSwitch
            status={checked}
            onToggle={(e) => onToggle(e, action, tenantAutoAction)}
            disabled={disabled}
          />
        </>
      }
      innerFlat
    >
      <p className={"marginT10 primaryText"}>
        Prochaine occurance de cette action :{" "}
        {!tenantAutoAction && "non activé"}
        {tenantAutoAction &&
          (tenantAutoAction.action_date
            ? moment(tenantAutoAction.action_date).format("DD/MM/YYYY")
            : "non prédéfini")}
      </p>
      <p className={"marginT10"}>{action.commentaires}</p>
    </CardToggle>
  );
}
