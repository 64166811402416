import {
  BalanceDomain,
  BalanceTypeCategory,
} from "../../utils/constants/balances";
import Select from "../UI/Select";
import { useBalanceTypes } from "../../hooks/use-balances";
import { classnames } from "../../utils";

export default function BalanceTypeSelect({ name, domaineBalance, className }) {
  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  if (isLoading) return null;
  return (
    <Select
      label="Catégorie d'opération"
      name={name}
      className={classnames(className)}
    >
      <option value="">Sélectionner un type</option>
      {domaineBalance === BalanceDomain.TENANT &&
        [
          ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_APPEL],
          ...balanceTypesByCategory[BalanceTypeCategory.LOCATAIRE_PAIEMENT],
          ...balanceTypesByCategory[
            BalanceTypeCategory.LOCATAIRE_REMBOURSEMENT
          ],
        ].map((balanceType, i) => (
          <option value={balanceType.static_id} key={i}>
            {balanceType.text}
          </option>
        ))}
      {(domaineBalance === BalanceDomain.PROPERTY ||
        domaineBalance === BalanceDomain.FOLDER) && (
        <>
          {[
            ...balanceTypesByCategory[BalanceTypeCategory.CHARGE],
            ...balanceTypesByCategory[BalanceTypeCategory.TRAVAUX],
          ].map((balanceType, i) => (
            <option value={balanceType.static_id} key={i}>
              {balanceType.text}
            </option>
          ))}
        </>
      )}
    </Select>
  );
}
