import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Table } from "../UI/Table";
import { currencyFormatter, dateConvert } from "../../api/Functions";
import useBanks from "../../hooks/use-banks";
import { useEffect, useState } from "react";
import { useModal } from "../../hooks/use-modal";
import { useNordigenTransactions } from "../../hooks/use-nordigen";
import Button from "../UI/Button";
import Card from "../UI/Card";
import { handleAPIError } from "../../utils";

function BankSyncTransactions() {
  const nav = useNavigate();

  const { refreshBanks } = useBanks();
  const { accounts: uniqueIds } = useLocation().state || {};
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(null);
  const { transactions, synchroniseTransactions, isLoading } =
    useNordigenTransactions();

  const [Modal] = useModal(true);

  useEffect(() => {
    refreshBanks();
  }, [refreshBanks]);

  useEffect(() => {
    // Si pas de compte a synchroniser, on ferme la modal
    if (!uniqueIds || uniqueIds.length === 0) {
      nav("..", { replace: true });
    }
  }, [nav, uniqueIds]);

  if (!fetched) {
    return (
      <Modal size={"medium"}>
        <h2>Récupération de l'historique des transactions</h2>
        <p className={"marginT10"}>
          Les nouveaux comptes ont été enregistrés et sont prêts à être
          synchronisés automatiquement.
          <br />
          Vous pouvez lancer la récupération de l'historique des transactions
          pour ces comptes (récupération jusqu'à 3 mois en arrière) en cliquant
          sur le bouton "Synchroniser" si vous le souhaitez. Si vous cliquez sur
          "Fermer", seul les 15 derniers jours seront récupérés, à partir de la
          nuit prochaine.
        </p>
        <div className={"marginT10 flex justify-content-end gap10"}>
          <Button
            type={"button"}
            buttonStyle={"secondary"}
            isLoading={isLoading}
            onClick={async () => {
              try {
                await synchroniseTransactions(uniqueIds);
                setFetched(true);
              } catch (e) {
                setError(handleAPIError(e));
              }
            }}
          >
            Synchroniser
          </Button>
          <Button type={"button"} onClick={() => nav("..")}>
            Fermer
          </Button>
        </div>
        {error && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {error}
          </Card>
        )}
      </Modal>
    );
  }

  // Si pas de transac associée à un locataire, on ferme la modal
  if (transactions.length === 0) {
    return <Navigate to={".."} replace />;
  }

  return (
    <Modal>
      <h2>Transactions en lien avec avec des locataires</h2>
      <p className={"marginT10 marginB10"}>
        Nous avons détecté les transactions suivantes, qui semblent être liées
        avec des locataires actifs configurés.
      </p>
      <Table>
        <Table.Header>
          <Table.Column>Date</Table.Column>
          <Table.Column>Description</Table.Column>
          <Table.Column>Montant</Table.Column>
        </Table.Header>
        <Table.Body>
          {transactions?.map((transaction, i) => (
            <Table.Row key={i}>
              <Table.Cell>{dateConvert(transaction.date_transac)}</Table.Cell>
              <Table.Cell>{transaction.description}</Table.Cell>
              <Table.Cell>{currencyFormatter(transaction.montant)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="text-right marginT10">
        <Button type={"button"} onClick={() => nav("..")}>
          Fermer
        </Button>
      </div>
    </Modal>
  );
}

export default BankSyncTransactions;
