export const SIGNATURE_PRICE = 2.9;

export class SignatureContratStatus {
  static Opened = new SignatureContratStatus("OPENED");
  static Open = new SignatureContratStatus("OPEN");
  static Signed = new SignatureContratStatus("SIGNED");
  static Closed = new SignatureContratStatus("CLOSED");
  static Archived = new SignatureContratStatus("ARCHIVED");
  static Canceled = new SignatureContratStatus("CANCELED");

  constructor(status) {
    this.status = status;
  }
}

export class SignatureStatus {
  static Sent = new SignatureContratStatus("SENT");
  static Signed = new SignatureContratStatus("SIGNED");
  static Refused = new SignatureContratStatus("REFUSED");

  constructor(status) {
    this.status = status;
  }
}
