import styles from "./Select.module.css";
import { classnames } from "../../utils";

export default function SelectStandalone({
  label,
  mandatory,
  isInvalid,
  error,
  className,
  ...props
}) {
  return (
    <div className={classnames(styles.control, className)}>
      <label htmlFor={props.id || props.name}>
        {label}
        {mandatory && <span className={"blueStar"}> *</span>}
      </label>
      <div
        className={classnames(
          "margin-form",
          styles.selectWrapper,
          isInvalid && styles.error,
        )}
      >
        <select {...props} />
        {!props.hidden && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 5"
            fill="#0C86F5"
          >
            <path d="M0 0L5 5L10 0L8 0L5 3L2 0Z" />
          </svg>
        )}
      </div>
      {isInvalid ? <div className="error marginT5">{error}</div> : null}
    </div>
  );
}
