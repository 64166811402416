import { FieldArray, Form, Formik } from "formik";
import Select from "../UI/Select";
import Card from "../UI/Card";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import {
  TRANSACTION_CHILD_INITIAL_VALUE,
  TRANSACTION_SPLIT_FORM_INITIAL_VALUE,
  transactionChildValidationSchema,
  transactionSplitValidationSchema,
} from "../../models/transactionChild";
import Checkbox from "../UI/Checkbox";
import SplitTransacFields from "./SplitTransacFields";
import { PlusBank } from "../UI/Icons";
import { ModalAwareLink } from "../UI/Modal";
import { currencyFormatter } from "../../api/Functions";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { BalanceDomain } from "../../utils/constants/balances";

export default function TransactionSplitForm({
  transaction,
  splitTemplates,
  onSubmit,
}) {
  let initialValues;
  if (transaction?.balances.length > 0) {
    initialValues = toInitialValuesFromSchema(
      transactionSplitValidationSchema,
      transaction,
      TRANSACTION_SPLIT_FORM_INITIAL_VALUE,
    );
  } else {
    initialValues = TRANSACTION_SPLIT_FORM_INITIAL_VALUE;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(sanitizeValues(values))}
      enableReinitialize={true}
      validationSchema={transactionSplitValidationSchema}
    >
      {({ values, setFieldValue, handleChange }) => {
        function changeTemplateSplit(e) {
          setFieldValue("template", e.target.value);
          if (e.target.value === "") {
            setFieldValue("balances", [TRANSACTION_CHILD_INITIAL_VALUE]);
            return;
          }

          const balances = splitTemplates
            .find((filtered) => filtered.id === parseInt(e.target.value))
            .splits.map((template) => {
              return {
                ...toInitialValuesFromSchema(
                  transactionChildValidationSchema,
                  template,
                  TRANSACTION_CHILD_INITIAL_VALUE,
                ),
              };
            });
          setFieldValue("balances", balances);
        }

        let totalSplit = 0;
        values.balances.map(
          (transac) => (totalSplit = totalSplit + parseFloat(transac.montant)),
        );

        return (
          <Form>
            <h2 className={"margin0"}>Découper une transaction</h2>
            <Card className={"marginT10 marginB10"}>
              <p>
                <span className={"primaryText"}>Montant : </span>
                {currencyFormatter(transaction?.montant)}
              </p>
              <p>
                <span className={"primaryText"}>Description :</span>
                {transaction?.description}
              </p>
            </Card>
            {splitTemplates.length > 0 ? (
              <Select name="template" onChange={(e) => changeTemplateSplit(e)}>
                <option value="">Utilisez un template de découpe</option>
                {splitTemplates?.map((template, i) => (
                  <option value={template.id} key={i}>
                    {template.nom}
                  </option>
                ))}
              </Select>
            ) : (
              <p className={"secondaryText marginT10 marginB10 text-center"}>
                Vous pouvez définir des templates pour découper vos transactions
                automatiquement dans{" "}
                <ModalAwareLink to={"/bailleur/finances#configuration"}>
                  Finances > Configuration
                </ModalAwareLink>
              </p>
            )}
            <FieldArray
              name="balances"
              render={(arrayHelpers) => (
                <>
                  {values.balances.map((child, index) => (
                    <SplitTransacFields
                      onDelete={() => arrayHelpers.remove(index)}
                      onAffectationChange={(prefix, e, domaineBalance) => {
                        if (domaineBalance !== child.domaine) {
                          let fieldToReset;
                          if (domaineBalance === BalanceDomain.TENANT) {
                            fieldToReset = `${prefix}.id_bien`;
                          } else if (
                            domaineBalance === BalanceDomain.PROPERTY
                          ) {
                            fieldToReset = `${prefix}.id_tenant`;
                          }
                          setFieldValue(fieldToReset, "");
                          setFieldValue(`${prefix}.type`, "");
                          setFieldValue(`${prefix}.domaine`, domaineBalance);
                        }
                        handleChange(e);
                      }}
                      name={"balances"}
                      index={index}
                      domaineBalance={child.domaine}
                      key={index}
                    />
                  ))}
                  <Card
                    className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
                    onClick={() =>
                      arrayHelpers.push(TRANSACTION_CHILD_INITIAL_VALUE)
                    }
                  >
                    <PlusBank />
                    <p className={"secondaryText marginL10"}>
                      Ajouter une entrée
                    </p>
                  </Card>
                </>
              )}
            />
            {values.template &&
              values.template !== "" &&
              splitTemplates.length > 0 && (
                <Checkbox name={"update_template"}>
                  Mettre à jour le template avec ces valeurs
                </Checkbox>
              )}
            {!isNaN(totalSplit) &&
              (Math.abs(totalSplit) > Math.abs(transaction?.montant) ? (
                <Card type={"error"} className={"marginB10 padding10"}>
                  Attention, le montant total des transactions découpées est de{" "}
                  {currencyFormatter(totalSplit)} et dépasse le montant de la
                  transaction initiale (
                  {currencyFormatter(transaction?.montant)})
                </Card>
              ) : (
                <Card className={"marginB10 padding10"}>
                  Montant total des transactions découpées :{" "}
                  {currencyFormatter(totalSplit)}
                </Card>
              ))}

            <div className={"text-right"}>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
