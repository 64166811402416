import Card from "../UI/Card";
import ButtonLink from "../UI/ButtonLink";
import Button from "../UI/Button";
import { PropertyStatus } from "../../models/property";
import Tabs from "../UI/Tabs";
import usePropertyFolders from "../../hooks/use-property-folders";
import cloneDeep from "lodash/cloneDeep";
import PropertyList from "./PropertyList";
import { deleteDraft } from "../../api/Draft";
import useAuth from "../../hooks/use-auth";

export const ROOT_PATH = "/bailleur/properties/";

export function buildPropertyFoldersTree(propertyFolders, properties) {
  const propertyFoldersById = Object.fromEntries(
    propertyFolders.map((pf) => {
      pf.properties = [];
      pf.property_folders = [];
      return [pf.id, pf];
    }),
  );
  for (let property of properties) {
    if (property.id_property_folder) {
      propertyFoldersById[property.id_property_folder]?.properties.push(
        property,
      );
    }
  }
  for (let pf of propertyFolders) {
    if (pf.id_parent) {
      propertyFoldersById[pf.id_parent]?.property_folders.push(pf);
    }
  }
  return cloneDeep({
    property_folders: propertyFolders.filter((pf) => !pf.id_parent),
    properties: properties.filter((p) => !p.id_property_folder),
  });
}

export function findPropertyFolder(propertyFolders, id) {
  if (!propertyFolders || propertyFolders.length === 0) return null;
  return (
    propertyFolders.find((pf) => pf.id === id) ??
    propertyFolders
      .map((pf) => findPropertyFolder(pf.property_folders, id))
      .find((pf) => pf !== null)
  );
}

export function filterPropertiesAndFoldersByStatus(
  propertiesAndFolders,
  status,
) {
  if (propertiesAndFolders === null) return null;

  const filtered = { ...propertiesAndFolders };
  filtered.properties =
    filtered.properties
      ?.filter((p) => p.status === status)
      .sort((p1, p2) =>
        p1.abonnement_actif === p2.abonnement_actif
          ? 0
          : p1.abonnement_actif
          ? -1
          : 1,
      ) ?? [];

  // Quand on cherche les actifs, si un sous-dossier est archivé, on sait deja qu'il n'a pas d'enfant actif
  // On peut deja filtrer tous les sous-dossiers qui n'ont pas le status spécifié
  if (status === PropertyStatus.Actif.status) {
    filtered.property_folders =
      filtered.property_folders?.filter((pf) => pf.status === status) ?? [];
  }

  // Dans les autres cas, on filtre recursivement les sous-dossiers
  filtered.property_folders =
    filtered.property_folders
      ?.map((pf) => filterPropertiesAndFoldersByStatus(pf, status))
      .filter((pf) => pf !== null) ?? [];

  // S'il n'y a aucun bien ou sous-dossier avec le status spécifié, et que son status n'est pas le bon,
  // on retourne null pour qu'il n'apparaisse pas
  if (
    filtered.property_folders.length === 0 &&
    filtered.properties.length === 0 &&
    filtered.status !== undefined &&
    filtered.status !== status
  ) {
    return null;
  }

  return filtered;
}

export default function PropertyListShow({
  drafts,
  draftDeletedHandler,
  properties,
  onDuplicateProperty,
}) {
  const { propertyFolders } = usePropertyFolders();
  const { auth } = useAuth();

  const propertiesAndFolders = buildPropertyFoldersTree(
    propertyFolders,
    properties,
  );

  const tousMesBiens = filterPropertiesAndFoldersByStatus(
    propertiesAndFolders,
    PropertyStatus.Actif.status,
  );
  const archivedProperties = properties.filter(
    (property) => property.status === PropertyStatus.Archive.status,
  );
  const archive = filterPropertiesAndFoldersByStatus(
    propertiesAndFolders,
    PropertyStatus.Archive.status,
  );
  const brouillons = (
    <Card>
      {drafts && drafts.length > 0 ? (
        drafts.map((draft) => (
          <Card key={draft.id} className={"marginB10 flex align-items-center"}>
            {draft.json.nom}
            <ButtonLink
              to={`${ROOT_PATH}draft/${draft.id}`}
              buttonStyle="secondary"
              className={"marginL10"}
            >
              Modifier
            </ButtonLink>
            <Button
              onClick={async () => {
                try {
                  await deleteDraft(auth.accessToken, draft.id);
                  draftDeletedHandler(draft.id);
                } catch (error) {
                  console.error(error);
                }
              }}
              className={"marginL10"}
            >
              Supprimer
            </Button>
          </Card>
        ))
      ) : (
        <div>Aucun brouillon trouvé</div>
      )}
    </Card>
  );

  return (
    <Tabs id="prop-list">
      <div className="container bg-blue paddingT10 border-blue">
        <div className="content">
          <Tabs.TabList>
            <Tabs.Tab panelId="tous-mes-biens">Tous mes biens</Tabs.Tab>
            <Tabs.Tab panelId="brouillons">
              Brouillons({drafts?.length})
            </Tabs.Tab>
            <Tabs.Tab panelId="archives">
              Archivés({archivedProperties.length})
            </Tabs.Tab>
          </Tabs.TabList>
        </div>
      </div>
      <div className="blueSpace"></div>
      <div className="content">
        <Tabs.TabPanel id="tous-mes-biens">
          <PropertyList
            propertiesAndFolders={tousMesBiens}
            onDuplicateProperty={onDuplicateProperty}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel id="brouillons">{brouillons}</Tabs.TabPanel>
        <Tabs.TabPanel id="archives">
          <PropertyList
            propertiesAndFolders={archive}
            onDuplicateProperty={onDuplicateProperty}
          />
        </Tabs.TabPanel>
      </div>
    </Tabs>
  );
}
