import Tabs from "./Tabs";
import Button from "./Button";
import { saveDraft, updateDraft } from "../../api/Draft";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/use-auth";
import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { flattenKeys } from "../../utils";
import Card from "./Card";
import FormikSubmitButton from "./FormikSubmitButton";

const TabbedFormContext = createContext({});

const TabbedForm = forwardRef(
  (
    {
      children,
      id,
      formikProps,
      isDraft,
      draftId,
      draftType,
      setDraftId,
      onDraftSaved,
    },
    ref,
  ) => {
    const [tabsInError, setTabsInError] = useState([]);
    const addTabInError = useCallback((tabId) => {
      setTabsInError((cur) => {
        if (cur.includes(tabId)) {
          return cur;
        }
        return [...cur, tabId];
      });
    }, []);
    const removeTabInError = useCallback((tabId) => {
      setTabsInError((cur) => cur.filter((id) => tabId !== id));
    }, []);

    const [panelInputs, setPanelInputs] = useState({});
    const registerPanelInputs = useCallback((panelId, inputs) => {
      setPanelInputs((cur) => {
        const newPanelInputs = { ...cur };
        newPanelInputs[panelId] = inputs;
        return newPanelInputs;
      });
    }, []);
    const unregisterPanelInputs = useCallback((panelId) => {
      setPanelInputs((cur) => {
        const newPanelInputs = { ...cur };
        delete newPanelInputs[panelId];
        return newPanelInputs;
      });
    }, []);

    const errors = useMemo(
      () => flattenKeys(formikProps.errors),
      [formikProps.errors],
    );
    const touched = useMemo(
      () => flattenKeys(formikProps.touched),
      [formikProps.touched],
    );

    const actionsProps = {
      formikProps,
      isDraft,
      draftId,
      draftType,
      setDraftId,
      onDraftSaved,
    };

    return (
      <>
        <Tabs id={id} ref={ref}>
          <TabbedFormContext.Provider
            value={{
              errors,
              touched,
              panelInputs,
              actionsProps,
              tabsInError,
              addTabInError,
              removeTabInError,
              registerPanelInputs,
              unregisterPanelInputs,
            }}
          >
            {children}
          </TabbedFormContext.Provider>
        </Tabs>
      </>
    );
  },
);

function Actions({ error }) {
  const {
    actionsProps: {
      formikProps,
      isDraft,
      draftId,
      draftType,
      setDraftId,
      onDraftSaved,
    },
  } = useContext(TabbedFormContext);
  const { isFirstTab, isLastTab, selectNextTab, selectPreviousTab } =
    Tabs.useActiveTab();
  const { isSubmitting, values, resetForm } = formikProps;
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { tabsInError } = useContext(TabbedFormContext);

  async function saveAndContinueHandler(chgTab) {
    let newDraft;
    try {
      if (draftId) {
        newDraft = await updateDraft(
          draftId,
          values,
          draftType,
          auth.accessToken,
        );
      } else {
        newDraft = await saveDraft(values, draftType, auth.accessToken);
        setDraftId(newDraft.id);
      }
      onDraftSaved(newDraft);
      resetForm({
        values,
      });
      chgTab ? selectNextTab() : navigate("../");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <div className={"flex wrap gap10 justify-content-end"}>
        <Button type="button" onClick={selectPreviousTab} disabled={isFirstTab}>
          Précédent
        </Button>
        <Button type="button" onClick={selectNextTab} disabled={isLastTab}>
          Suivant
        </Button>
        {isDraft && (
          <Button
            type="button"
            onClick={() => saveAndContinueHandler(!isLastTab)}
            disabled={isSubmitting}
          >
            {isLastTab
              ? "Enregistrer le brouillon"
              : "Enregistrer et continuer"}
          </Button>
        )}
        {isLastTab && (
          <FormikSubmitButton disabled={tabsInError.length > 0}>
            Valider
          </FormikSubmitButton>
        )}
      </div>
      {tabsInError.length > 0 && (
        <Card type="error" className={"marginT10 padding10"}>
          Il y a des erreurs dans le formulaire
        </Card>
      )}
      {error && (
        <Card type={"error"} className={"padding10 marginT10"}>
          {error}
        </Card>
      )}
    </div>
  );
}

function TabPanel({ children, id, ...rest }) {
  const {
    errors,
    touched,
    panelInputs,
    addTabInError,
    removeTabInError,
    registerPanelInputs,
    unregisterPanelInputs,
  } = useContext(TabbedFormContext);
  const tabRef = useRef();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!tabRef.current) return;
    const inputs = Array.from(tabRef.current.querySelectorAll("[name]")).map(
      (elem) => elem.name,
    );
    registerPanelInputs(id, inputs);
    setIsInitialized(true);
    return () => {
      unregisterPanelInputs(id);
    };
  }, [id, registerPanelInputs, unregisterPanelInputs]);

  useEffect(() => {
    let inputs = panelInputs[id] ?? [];
    if (tabRef.current) {
      inputs = Array.from(tabRef.current.querySelectorAll("[name]")).map(
        (elem) => elem.name,
      );
    }

    let hasError = false;
    for (const input of inputs) {
      if (errors.includes(input) && touched.includes(input)) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      addTabInError(id);
    } else {
      removeTabInError(id);
    }
  }, [errors, touched, panelInputs, id, addTabInError, removeTabInError]);

  return (
    <Tabs.TabPanel
      id={id}
      ref={tabRef}
      shouldRenderInactive={!isInitialized}
      {...rest}
    >
      {children}
    </Tabs.TabPanel>
  );
}

function Tab({ children, panelId, ...rest }) {
  const { tabsInError } = useContext(TabbedFormContext);
  const hasError = tabsInError.includes(panelId);
  return (
    <Tabs.Tab panelId={panelId} type={hasError ? "error" : undefined} {...rest}>
      <span>{children}</span>
    </Tabs.Tab>
  );
}

TabbedForm.TabList = Tabs.TabList;
TabbedForm.TabPanel = TabPanel;
TabbedForm.Tab = Tab;
TabbedForm.Actions = Actions;

export default TabbedForm;
