import { useFormikContext } from "formik";
import Card from "../../UI/Card";
import {
  getLastAnniversaryYear,
  getNewLoyerFromIndice,
  getNextAnniversaryDate,
} from "../../../utils";
import TextInput from "../../UI/TextInput";
import moment from "moment";
import useIrls from "../../../hooks/use-irls";
import Select from "../../UI/Select";
import { currencyFormatter } from "../../../api/Functions";
import { useCallback, useEffect } from "react";

function RevisionForm({ tenant, property }) {
  const { irls } = useIrls();
  const { values, setFieldValue } = useFormikContext();

  const irlTrimestre = tenant?.irl_trimestre;
  const dateDebut = tenant?.debut;

  const getIndice = useCallback(
    (t) => {
      let lastYear;
      let currYear;
      const trimestre = parseInt(t);
      const trimestreActuel = Math.floor(moment().month() / 4) + 1;

      if (getNextAnniversaryDate(dateDebut).diff(moment(), "months") < 1) {
        lastYear = getLastAnniversaryYear(dateDebut);
        currYear = getLastAnniversaryYear(dateDebut) + 1;
      } else {
        lastYear = getLastAnniversaryYear(dateDebut) - 1;
        currYear = getLastAnniversaryYear(dateDebut);
      }

      if (trimestre > trimestreActuel) {
        currYear -= 1;
        lastYear -= 1;
      }

      const lastYearIndice = irls?.find(
        (irl) => irl.annee === lastYear && irl.trimestre === trimestre,
      )?.indice;
      const irlNewIndice = irls?.find(
        (irl) => irl.annee === currYear && irl.trimestre === trimestre,
      )?.indice;
      return { lastYear, currYear, lastYearIndice, irlNewIndice };
    },
    [irls, dateDebut],
  );

  const setForm = useCallback(
    (t) => {
      const { lastYear, currYear, lastYearIndice, irlNewIndice } = getIndice(t);
      setFieldValue("irl_initial_trimestre", parseInt(t));
      setFieldValue("irl_last_year_trimestre", `T${t}`);
      setFieldValue("irl_last_year_annee", lastYear);
      setFieldValue("irl_last_year_indice", lastYearIndice);
      setFieldValue("irl_new_trimestre", `T${t}`);
      setFieldValue("irl_new_annee", currYear);
      setFieldValue("irl_new_indice", irlNewIndice);
    },
    [getIndice, setFieldValue],
  );

  useEffect(() => {
    if (irlTrimestre) {
      setForm(irlTrimestre);
    }
  }, [setForm, irlTrimestre]);

  if (!tenant) return null;

  let revisionStatus;

  if (moment().isBefore(tenant.last_revision_date)) {
    revisionStatus = (
      <Card type={"error"} className={"padding10 text-center"}>
        Vous avez déjà fait une révision de loyer pour cette année.
        <br />
        Celle si sera applicable à partir du{" "}
        {moment(tenant.last_revision_date).format("DD MMMM YYYY")}
      </Card>
    );
  } else if (
    getNextAnniversaryDate(tenant.debut).diff(moment(), "months") < 1
  ) {
    revisionStatus = (
      <Card type={"green"} className={"padding10 text-center"}>
        La date anniversaire du bail était il y a plus de 11 mois.
        <br /> Vous pouvez dès à présent envoyer le courrier de révision de
        loyer. <br /> Il sera applicable dès le mois prochain.
      </Card>
    );
  } else if (moment().diff(tenant.debut, "years") === 0) {
    revisionStatus = (
      <Card type={"error"} className={"padding10 text-center"}>
        Le locataire est arrivé il y a moins d'un an.
        <br />
        Vous n'avez légalement pas le droit de faire une révision de loyer
      </Card>
    );
  } else if (
    moment(tenant.last_revision_date).isBetween(
      getLastAnniversaryYear(tenant.debut),
      moment(),
    )
  ) {
    revisionStatus = (
      <Card type={"error"} className={"padding10 text-center"}>
        Vous avez déjà fait une révision de loyer sur l'année écoulée.
        <br />
        Vous n'avez légalement pas le droit de faire une révision de loyer.
        <br />
        Prochaine révision possible :{" "}
        {getNextAnniversaryDate(tenant.debut).format("DD MMMM YYYY")}
      </Card>
    );
  }

  function getLoyerAfterRevision(tenant, values) {
    let loyer_hc = 0;
    let charges = 0;

    if (tenant.type_charge === "provision") {
      loyer_hc = getNewLoyerFromIndice(
        tenant.loyer_hc,
        values.irl_last_year_indice,
        values.irl_new_indice,
      );
      return ` ${currencyFormatter(loyer_hc)} hors charge`;
    } else {
      loyer_hc = getNewLoyerFromIndice(
        tenant.loyer_hc,
        values.irl_last_year_indice,
        values.irl_new_indice,
      );
      charges = getNewLoyerFromIndice(
        tenant.charges,
        values.irl_last_year_indice,
        values.irl_new_indice,
      );
      return ` ${currencyFormatter(loyer_hc + charges)} (${currencyFormatter(
        loyer_hc,
      )} hors charge + ${currencyFormatter(charges)} de charges)`;
    }
  }

  return (
    <>
      <h2 className={"margin0 text-center"}>Faire une révision de loyer</h2>
      <p className={"text-center marginT10"}>
        Loyer en cours à la date de révision :{" "}
        {tenant.type_charge === "provision" ? (
          <>{currencyFormatter(tenant.loyer_hc)} hors charge</>
        ) : (
          <>
            {currencyFormatter(tenant.loyer_hc + tenant.charges)} (
            {currencyFormatter(tenant.loyer_hc)} +{" "}
            {currencyFormatter(tenant.charges)} de charges)
          </>
        )}
      </p>
      <p className={"marginT10 text-center marginB10"}>
        Date de la dernière révision de loyer :{" "}
        {tenant.last_revision_date
          ? moment(tenant.last_revision_date).format("DD MMMM YYYY")
          : "jamais"}
      </p>
      {(property.indice_dpe === "G" || property.indice_dpe === "F") && (
        <Card type={"error"} className={"padding10 marginB10"}>
          Votre logement à un indice DPE F ou G. Il n'est pas légal de réaliser
          une augmentation de loyer
        </Card>
      )}
      {revisionStatus}
      <p className={"marginT10"}>Trimestre de révision du loyer</p>
      <div className={"flex"}>
        <Select
          name="irl_initial_trimestre"
          className={"marginT0 marginR10"}
          onChange={(e) => setForm(e.target.value)}
        >
          <option value="">Sélectionnez un trimestre</option>
          <option value="1">Trimestre 1</option>
          <option value="2">Trimestre 2</option>
          <option value="3">Trimestre 3</option>
          <option value="4">Trimestre 4</option>
        </Select>
      </div>
      {values.irl_new_indice ? (
        <>
          <p>Indice de l'année précédente</p>
          <div className={"flex"}>
            <TextInput
              type="text"
              name="irl_last_year_trimestre"
              placeholder={"Trimestre"}
              className={"marginR10"}
              disabled
            />
            <TextInput
              type="text"
              name="irl_last_year_annee"
              placeholder={"Année"}
              className={"marginR10"}
              disabled
            />
            <TextInput
              type="text"
              name="irl_last_year_indice"
              placeholder={"Indice"}
              disabled
            />
          </div>
          <p>Nouvel indice applicable</p>
          <div className={"flex"}>
            <TextInput
              type="text"
              name="irl_new_trimestre"
              placeholder={"Trimestre"}
              className={"marginR10"}
              disabled
            />
            <TextInput
              type="text"
              name="irl_new_annee"
              placeholder={"Année"}
              className={"marginR10"}
              disabled
            />
            <TextInput
              type="text"
              name="irl_new_indice"
              placeholder={"Indice"}
              disabled
            />
          </div>

          <p className={"primaryText text-center"}>
            Le loyer après la révision sera de
            {getLoyerAfterRevision(tenant, values)}
          </p>
          <p className={"text-center secondaryText marginT10"}>
            Attention ce montant ne sera dû qu’à compter du prochain loyer
            suivant la demande de révision.
          </p>
        </>
      ) : (
        values.irl_initial_trimestre && (
          <Card type={"error"} className={"padding10"}>
            L'indice que vous cherchez n'est pas encore sortit :
            <ul>
              <li>
                Indice du trimestre {values.irl_new_trimestre}{" "}
                {values.irl_new_annee}
              </li>
            </ul>
          </Card>
        )
      )}
    </>
  );
}

export default RevisionForm;
