import styles from "./TextInput.module.css";
import { classnames } from "../../utils";

export default function TextInputStandalone({
  label,
  className,
  mandatory,
  unit,
  size,
  subText,
  placeholder,
  error,
  ...props
}) {
  const classes = classnames(styles.control, className, error && styles.error);

  const min =
    props.type === "number" && props.min === undefined ? 0 : props.min;

  return (
    <div className={classes}>
      {label && (
        <>
          <div className={"flex"}>
            <label htmlFor={props.id || props.name}>
              {label}
              {mandatory && <span className={"blueStar"}> *</span>}
            </label>
          </div>
          {subText ? <p className={"secondaryText"}>{subText}</p> : ""}
        </>
      )}
      {unit ? (
        <div className={styles.inputGroup}>
          <input
            {...props}
            placeholder={placeholder && `Ex : ${placeholder}`}
            className={classnames(
              styles.inputField,
              styles.textInput,
              styles[size],
              "flex-grow",
            )}
            min={min}
          />
          <div className={classnames(styles.inputUnit)}>{unit}</div>
        </div>
      ) : (
        <input
          {...props}
          placeholder={placeholder && `Ex : ${placeholder}`}
          className={classnames(styles.textInput, styles[size])}
          min={min}
        />
      )}
      {error && <div>{error}</div>}
    </div>
  );
}
