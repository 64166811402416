import { bool, object } from "yup";
import { dateMax, stringMax } from "./utils";

export class FileStatus {
  static valide = new FileStatus(1);
  static aValider = new FileStatus(2);

  constructor(status) {
    this.status = status;
  }
}

export const documentSchema = object({
  tag: stringMax(250).required(" "),
  shared: bool().notRequired(),
  filename: stringMax(250).notRequired(),
});

export const documentSchemaWithValidity = documentSchema.concat(
  object({
    valid_until: dateMax().required(" "),
  }),
);

export const documentSchemaWithDateSignature = documentSchema.concat(
  object({
    date_signature: dateMax().required(" "),
  }),
);
export const documentSchemaQuittance = documentSchema.concat(
  object({
    date_quittance: dateMax().required(" "),
  }),
);

export const DOCUMENT_LIST = [
  {
    nom: "Bail",
    tag: "bail",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    requiredTenant: true,
    fields: [
      { type: "date", name: "date_signature", label: "Date de signature" },
    ],
    needValidation: true,
    validationSchema: documentSchemaWithDateSignature,
  },
  {
    nom: "Etat des lieux",
    tag: "edl",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    requiredTenant: true,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Assurance habitation",
    tag: "assurance_habitation",
    vueBailleur: true,
    addLocataire: true,
    addProperty: false,
    vueLocataire: true,
    needValidation: true,
    requiredTenant: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Justificatif domicile",
    tag: "domicile",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Justificatif professionnel",
    tag: "pro",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Justificatif identité",
    tag: "identite",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [
      { type: "date", name: "date_signature", label: "Date de signature" },
    ],
    needValidation: true,
    validationSchema: documentSchemaWithDateSignature,
  },
  {
    nom: "Courrier",
    tag: "courrier",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Congé",
    tag: "conge",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Avenant",
    tag: "avenant",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Visale",
    tag: "visale",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "GLI",
    tag: "gli",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Quittance",
    tag: "quittance",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [
      {
        type: "date",
        name: "date_quittance",
        label: "Periode de la quittance",
      },
    ],
    needValidation: true,
    validationSchema: documentSchemaQuittance,
  },
  {
    nom: "Caution",
    tag: "caution",
    vueBailleur: true,
    vueLocataire: true,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Décompte",
    tag: "decompte",
    vueBailleur: true,
    vueLocataire: false,
    addLocataire: true,
    addProperty: false,
    fields: [],
    needValidation: true,
    validationSchema: documentSchema,
  },
  {
    nom: "Taxe foncière",
    tag: "taxe_fonciere",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Taxe d'ordure ménagère",
    tag: "taxe_om",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic DPE",
    tag: "DPE",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic mesurage",
    tag: "mesurage",
    vueProperty: true,
    fields: [
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchema,
  },
  {
    nom: "Diagnostic électrique",
    tag: "elec",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic pollution",
    tag: "pollution",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic plomb",
    tag: "plomb",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic amiante",
    tag: "amiante",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic gaz",
    tag: "gaz",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Diagnostic termite",
    tag: "termite",
    vueProperty: true,
    fields: [
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchema,
  },
  {
    nom: "Diagnostic risques naturels",
    tag: "risque_naturel",
    vueProperty: true,
    fields: [
      { type: "date", name: "valid_until", label: "Date de fin de validité" },
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchemaWithValidity,
  },
  {
    nom: "Réglement de copropriété",
    tag: "copro",
    vueProperty: true,
    fields: [
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
      },
    ],
    validationSchema: documentSchema,
  },
  {
    nom: "RIB",
    tag: "rib",
    vueProperty: true,
    requiredProperty: true,
    fields: [
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    validationSchema: documentSchema,
  },
  {
    nom: "Autre document",
    tag: "autre",
    vueBailleur: true,
    vueLocataire: true,
    addLocataire: true,
    addProperty: true,
    vueProperty: true,
    fields: [
      {
        type: "checkbox",
        name: "shared",
        label: "Partagé avec le locataire",
        checked: true,
      },
    ],
    needValidation: true,
    validationSchema: documentSchema,
  },
];
