export function monthsToRecurrence(months) {
  switch (months) {
    case 1:
      return "par mois";
    case 12:
      return "par an";
    default:
      return "";
  }
}
