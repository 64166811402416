import useStripePrices, {
  useStripeAbonnementPrice,
} from "../../hooks/use-stripe-prices";
import RadioStandalone from "../UI/RadioStandalone";
import { useCallback, useEffect, useState } from "react";
import useStripePaymentMethods from "../../hooks/use-stripe-payment-methods";
import useStripePayment from "./Stripe";
import PaymentMethod from "./PaymentMethod";
import Button from "../UI/Button";
import AddressFacturation from "../Account/AddressFacturation";
import Card from "../UI/Card";
import useAccount from "../../hooks/use-account";
import CGVCheckbox from "./CGVCheckbox";
import { handleAPIError } from "../../utils";
import RadioGroup from "../UI/RadioGroup";
import { currencyFormatter } from "../../api/Functions";
import useLoader from "../../hooks/use-loader";
import Spinner from "../UI/Spinner";
import moment from "moment";
import SelectStandalone from "../UI/SelectStandalone";
import { TypeAbonnement } from "../../utils/constants/account";
import { AbonnementType } from "../Account/MonCompte";
import useProperties from "../../hooks/use-properties";
import TextInputStandalone from "../UI/TextInputStandalone";
import { PropertyStatus } from "../../models/property";

function ShowAbonnement({
  price,
  nbBiens,
  checked,
  isCurrentSubscription,
  onChange,
}) {
  return (
    <RadioStandalone
      name={"subscription"}
      onChange={() => onChange(price.id)}
      checked={checked}
      disabled={isCurrentSubscription}
      label={
        <>
          <AbonnementType
            type={price.type_abonnement}
            stripePriceId={price.id}
            nbBiens={nbBiens}
          />
          {isCurrentSubscription && " (abonnement actuel)"}
        </>
      }
    />
  );
}

export default function BuySubscriptionForm({ Modal, onSubscribed }) {
  const [nbBiens, setNbBiens] = useState(2);
  const [months, setMonths] = useState(1);
  const [codePromo, setCodePromo] = useState("");
  const [codePromoPreview, setCodePromoPreview] = useState();
  const { prices } = useStripePrices("abonnement");
  const { properties } = useProperties();
  const {
    paymentMethods,
    refresh: refreshPaymentMethods,
    defaultPaymentMethod,
  } = useStripePaymentMethods();
  const { initiateSetup } = useStripePayment();
  const { account, hasValidAddress, subscribe } = useAccount();
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [preview, setPreview] = useState("");
  const [previewingPayment, previewPayment] = useLoader(
    useCallback((values) => subscribe(values, true), [subscribe]),
  );
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
  const [message, setMessage] = useState("");
  const [cgv, setCgv] = useState(false);
  const { price: currentAbonnement } = useStripeAbonnementPrice(
    account.stripe_price_abonnement,
    account.nb_biens_abonnement,
  );

  const previewSubscription = useCallback(
    async (subscriptionPrice) => {
      try {
        const res = await previewPayment({
          preview: true,
          stripe_price_abonnement: subscriptionPrice,
          nb_biens: nbBiens,
          code_promo: codePromo,
        });
        const paymentDate = moment(res.data.payment_date);
        let paymentDateText = `le ${paymentDate.format("DD/MM/YYYY")}`;
        if (paymentDate.isSame(moment(), "day")) {
          paymentDateText = "immédiatement";
        }
        const trialText =
          res.data.trial > 0
            ? `Vous avez une période d'essai de ${res.data.trial} jours. `
            : "";
        setPreview(
          `${trialText}Le prochain paiement sera de ${currencyFormatter(
            res.data.montant,
          )}, prélevés ${paymentDateText}.`,
        );
        setCodePromoPreview(res.data.promo);
      } catch (e) {
        console.error(e);
      }
    },
    [codePromo, nbBiens, previewPayment],
  );

  useEffect(() => {
    if (subscriptionPrice) {
      previewSubscription(subscriptionPrice);
    } else {
      setPreview("");
    }
  }, [previewSubscription, subscriptionPrice]);

  useEffect(() => {
    if (paymentMethods.length === 1) {
      setPaymentMethod(paymentMethods[0].id);
    } else {
      setPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod, paymentMethods]);

  let initialNbBiens =
    account.nb_biens_abonnement ||
    Math.max(
      Math.min(
        properties.filter(
          (property) => property.status === PropertyStatus.Actif.status,
        ).length,
        7,
      ),
      2,
    );
  useEffect(() => {
    setNbBiens(initialNbBiens);
  }, [initialNbBiens]);

  const initialMonths = currentAbonnement?.months || 1;
  useEffect(() => {
    setMonths(initialMonths);
  }, [initialMonths]);

  useEffect(() => {
    const filteredPrices = prices.filter((price) => price.months === months);
    if (filteredPrices.length === 1) {
      // Automatically select the only available price
      if (
        filteredPrices[0].id !== account.stripe_price_abonnement ||
        nbBiens !== account.nb_biens_abonnement ||
        !account.autorenew_abonnement
      ) {
        setSubscriptionPrice(filteredPrices[0].id);
      } else {
        setSubscriptionPrice("");
      }
    }
  }, [
    account.autorenew_abonnement,
    account.nb_biens_abonnement,
    account.stripe_price_abonnement,
    months,
    nbBiens,
    prices,
  ]);

  const [isSubmitting, submitHandler] = useLoader(async (e) => {
    e.preventDefault();
    setMessage("");

    if (!subscriptionPrice) {
      setMessage(
        "Veuillez choisir un abonnement, différent de votre abonnement actuel.",
      );
      return;
    }

    if (!paymentMethod) {
      setMessage(
        "Veuillez renseigner une carte de paiement pour les paiements récurrents.",
      );
      return;
    }

    if (!hasValidAddress) {
      setMessage("Veuillez renseigner une adresse de facturation valide.");
      return;
    }

    if (!cgv) {
      setMessage("Veuillez accepter les conditions générales de vente.");
      return;
    }

    try {
      await subscribe({
        payment_method_id: paymentMethod,
        stripe_price_abonnement: subscriptionPrice,
        nb_biens: nbBiens,
        code_promo: codePromo,
      });
      onSubscribed();
    } catch (e) {
      setMessage(handleAPIError(e));
    }
  });

  return (
    <Modal
      size={"intermediate"}
      onClose={() => {
        setMessage("");
        setPaymentMethod(defaultPaymentMethod);
        setPreview("");
        setCgv(false);
        setNbBiens(initialNbBiens);
        setMonths(initialMonths);
        setCodePromo("");
      }}
    >
      <form onSubmit={submitHandler}>
        <img
          src={`/photo_abonnements.png`}
          alt="Balance locataire"
          className={"center marginB10"}
        />
        <h2 className={"cardTitle text-center marginB20"}>Abonnements</h2>
        <SelectStandalone
          label={"Nombre de biens que vous souhaitez gérer avec Qalimo"}
          mandatory
          onChange={(e) => {
            setSubscriptionPrice("");
            setNbBiens(parseInt(e.target.value));
          }}
          value={nbBiens}
        >
          {Array.from({ length: 5 }, (_, i) => (
            <option key={i} value={i + 2}>
              {i + 2} biens
            </option>
          ))}
          <option value={7}>7 biens et plus</option>
        </SelectStandalone>
        Récurrence des paiements :<span className={"blueStar"}> *</span>
        <RadioGroup>
          <RadioStandalone
            label={"paiement mensuel"}
            checked={months === 1}
            onChange={() => setMonths(1)}
          />
          <RadioStandalone
            label={"paiement annuel"}
            checked={months === 12}
            onChange={() => setMonths(12)}
          />
        </RadioGroup>
        Abonnement et montant :<span className={"blueStar"}> *</span>
        <RadioGroup styling={"button"} layout={"row-wrap"}>
          {prices
            .filter((price) => price.months === months)
            .map((price, i) => (
              <ShowAbonnement
                price={price}
                nbBiens={nbBiens}
                checked={subscriptionPrice === price.id}
                isCurrentSubscription={
                  price.id === account.stripe_price_abonnement &&
                  moment(account.expiration_abonnement) >= moment() &&
                  account.autorenew_abonnement &&
                  (account.nb_biens_abonnement === nbBiens ||
                    account.type_abonnement === TypeAbonnement.EARLYBIRD)
                }
                onChange={setSubscriptionPrice}
                key={i}
              />
            ))}
        </RadioGroup>
        {TypeAbonnement.GRATUIT === account.type_abonnement && (
          <>
            <TextInputStandalone
              label={"Code promo"}
              placeholder={"ABC123"}
              onBlur={(e) => setCodePromo(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setCodePromo(e.target.value);
                  e.preventDefault();
                }
              }}
            />
            {codePromoPreview && codePromoPreview.code && (
              <p className={"secondaryText"}>
                Code '{codePromoPreview.code}' :{" "}
                {codePromoPreview.valid
                  ? `${codePromoPreview.days} jours d'essai additionnels`
                  : "invalide"}
              </p>
            )}
          </>
        )}
        {preview && !previewingPayment && <Card>{preview}</Card>}
        {previewingPayment && <Spinner width="2em" />}
        <Card className="marginT10">
          <p className={"marginB20"}>
            <span className="cardTitle">Moyen de paiement </span>
          </p>
          <RadioGroup className={"gridColWrap300"} styling={"button"}>
            {paymentMethods.map((method, i) => (
              <RadioStandalone
                name="payment_method"
                onChange={() => setPaymentMethod(method.id)}
                checked={paymentMethod === method.id}
                key={i}
                label={<PaymentMethod method={method} flat />}
              />
            ))}
          </RadioGroup>
          <Button
            type="button"
            buttonStyle="link"
            className={"center marginT10"}
            onClick={() =>
              initiateSetup({
                onSetupSuccessful: refreshPaymentMethods,
              })
            }
          >
            Ajouter une nouvelle carte
          </Button>
        </Card>
        <AddressFacturation />
        <CGVCheckbox
          checked={cgv}
          onChange={() => setCgv((prevState) => !prevState)}
        />
        {message && (
          <Card type={"error"} className={"padding10 marginT10"}>
            {message}
          </Card>
        )}
        <div className={"text-right"}>
          <Button className={"marginT10"} isLoading={isSubmitting}>
            Valider
          </Button>
        </div>
      </form>
    </Modal>
  );
}
