import React, { useState } from "react";
import { Selectable } from "../UI/Selectable";
import useProperties from "../../hooks/use-properties";
import useTenants from "../../hooks/use-tenants";
import useTransactions from "../../hooks/use-transactions";
import { currencyFormatter, dateConvert } from "../../api/Functions";
import { Table } from "../UI/Table";
import Card from "../UI/Card";
import InitialesLogo from "../UI/InitialesLogo";
import { useModal } from "../../hooks/use-modal";
import TransactionSplitForm from "./TransactionSplitForm";
import Button from "../UI/Button";
import useFinanceSplitTemplates from "../../hooks/use-finance-split-templates";
import SelectStandalone from "../UI/SelectStandalone";
import { DotsAction, Plus } from "../UI/Icons";
import Dropdown from "../UI/Dropdown";
import ButtonCard from "../UI/ButtonCard";
import SelectPropertyTenantAction from "../UI/SelectPropertyTenantAction";
import StandaloneTextInputGroup from "../UI/StandaloneTextInputGroup";
import useDebouncedValue from "../../hooks/use-debounced-value";
import { TenantStatus } from "../../models/tenant";
import { BalanceDomain, BalanceType } from "../../utils/constants/balances";
import usePropertyFolders from "../../hooks/use-property-folders";
import { getParentIdPropertyFromDomain } from "../../utils/balances";

function TransactionList({ banks }) {
  const [pageIndex, setPageIndex] = useState(1);
  const [transaction, setTransaction] = useState(null);
  const [perPage, setPerPage] = useState(100);
  const [filterSearch, setFilterSearch] = useState("");
  const debouncedFilterSearch = useDebouncedValue(filterSearch);
  const [account, setAccount] = useState("");
  const [isBatchLoading, setIsBatchLoading] = useState(false);
  const {
    transactions,
    deleteMultipleTransactions,
    assignMultipleTransactions,
    deleteTransactionBalances,
    createTransactionBalances,
  } = useTransactions({
    params: {
      page: pageIndex,
      per_page: perPage,
      search: debouncedFilterSearch,
      account: account,
    },
  });
  const { properties } = useProperties();
  const { propertyFolders } = usePropertyFolders();

  const { tenants } = useTenants();
  const { splitTemplates, updateFinanceSplitTemplate } =
    useFinanceSplitTemplates();
  const [SplitModal, closeSplitModal, openSplitModal] = useModal();
  const [SelectTenantModal, closeSelectTenantModal, openSelectTenantModal] =
    useModal();
  const [
    SelectPropertyModal,
    closeSelectPropertyModal,
    openSelectPropertyModal,
  ] = useModal();

  function sanitizeTransactions(transactions) {
    const newTransactions = [];
    transactions.forEach((transaction) => {
      newTransactions.push(transaction);
      if (
        transaction.balances.length > 1 ||
        (transaction.balances.length === 1 &&
          transaction.balances[0].montant !== transaction.montant)
      ) {
        transaction.balances.forEach((balance) => {
          newTransactions.push(balance);
        });
      }
    });

    return newTransactions;
  }

  const sanitizedTransactions = sanitizeTransactions(transactions);

  async function handleMultipleChange(e, selectedTransactionIds) {
    if (e.target.value === "") return;
    setIsBatchLoading(true);
    const data = {
      id_tenant: null,
      id_bien: null,
      id_property_folder: null,
    };

    if (e.target.value !== "null") {
      const [domaineBalance, parentId] = e.target.value.split("|");
      data.domaine = domaineBalance;
      data[getParentIdPropertyFromDomain(domaineBalance)] = parentId;
    }

    try {
      await assignMultipleTransactions(selectedTransactionIds, data);
      // Reset du select
      e.target.value = "";
    } catch (e) {
      console.error(e);
    }
    setIsBatchLoading(false);
  }

  async function updateTransactionAffectationHandler(
    transaction,
    parentId,
    parentType,
  ) {
    const balance = {
      type: BalanceType.PAIEMENT,
      comment: transaction.description,
      montant: transaction.montant,
      date_transac: transaction.date_transac,
      id_bien: null,
      id_property_folder: null,
      id_tenant: null,
      domaine: parentType,
      [getParentIdPropertyFromDomain(parentType)]: parentId,
    };

    try {
      await createTransactionBalances(transaction.id, [balance]);
    } catch (error) {
      console.error(error);
    }
    closeSelectTenantModal();
    closeSelectPropertyModal();
  }

  async function deleteTransactionAffectationHandler(transactionId) {
    try {
      await deleteTransactionBalances(transactionId);
    } catch (error) {
      console.error(error);
    }
    closeSelectTenantModal();
    closeSelectPropertyModal();
  }

  function tenantLogo(tenantId) {
    const tenant = tenants.find((tenant) => tenant.id === tenantId);
    return (
      tenant && (
        <InitialesLogo
          nom={tenant.profile_nom.nom_profile}
          shape="rond"
          hover
        />
      )
    );
  }

  return (
    <>
      <SplitModal>
        <TransactionSplitForm
          transaction={transaction}
          splitTemplates={splitTemplates}
          onSubmit={async (newTransaction) => {
            try {
              if (newTransaction.update_template) {
                await updateFinanceSplitTemplate(
                  parseInt(newTransaction.template),
                  {
                    splits: newTransaction.balances,
                  },
                );
              }
              await createTransactionBalances(
                transaction.id,
                newTransaction.balances,
              );
              closeSplitModal();
              setTransaction(null);
            } catch (e) {
              console.error(e);
            }
          }}
        />
      </SplitModal>
      <SelectTenantModal
        header={<h2 className={"text-center"}>Affecter à un locataire</h2>}
      >
        {transaction && transaction.balances.length === 1 && (
          <ButtonCard
            className={"marginT5 marginB10"}
            display={"block"}
            padding="sm"
            onClick={() => deleteTransactionAffectationHandler(transaction.id)}
            buttonStyle={"error"}
          >
            Supprimer l'affectation
          </ButtonCard>
        )}
        <SelectPropertyTenantAction
          tenants={tenants}
          properties={properties}
          filtering={"tenants"}
          text={
            "Sélectionner le locataire auquel vous souhaitez affecter la transaction"
          }
          onClick={(parentId, parentType) =>
            updateTransactionAffectationHandler(
              transaction,
              parentId,
              parentType,
            )
          }
        />
      </SelectTenantModal>
      <SelectPropertyModal
        header={<h2 className={"text-center"}>Affecter à un bien</h2>}
      >
        {transaction && transaction.balances.length === 1 && (
          <ButtonCard
            className={"marginT5 marginB10"}
            display={"block"}
            padding="sm"
            onClick={() => deleteTransactionAffectationHandler(transaction.id)}
            buttonStyle={"error"}
          >
            Supprimer l'affectation
          </ButtonCard>
        )}

        <SelectPropertyTenantAction
          tenants={tenants}
          properties={properties}
          propertyFolders={propertyFolders}
          filtering={"properties"}
          text={
            "Sélectionner le bien auquel vous souhaitez affecter la transaction"
          }
          onClick={(parentId, parentType) =>
            updateTransactionAffectationHandler(
              transaction,
              parentId,
              parentType,
            )
          }
        />
      </SelectPropertyModal>
      <Card>
        <div className="flex wrap gap10 align-items-start">
          <SelectStandalone
            className={"marginB10"}
            name="bank"
            onChange={(e) => setAccount(e.target.value)}
          >
            <option key="0" value="">
              Selectionnez un compte
            </option>
            {banks.map((option) => (
              <option key={option.unique_id} value={option.unique_id}>
                {option.nom ? option.nom : option.bank_metadata.name}
              </option>
            ))}
          </SelectStandalone>
          <SelectStandalone
            className={"marginB10"}
            name="perPage"
            onChange={(e) => setPerPage(e.target.value)}
            defaultValue={"50"}
          >
            <option value="">Lignes par page</option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </SelectStandalone>
          <StandaloneTextInputGroup
            placeholder={"Recherche"}
            onChange={(e) => setFilterSearch(e.target.value)}
          />
        </div>
        <Selectable items={sanitizedTransactions}>
          <Table>
            <Table.Header>
              <Table.Column>
                <Selectable.Checkbox selectAll />
              </Table.Column>
              <Table.Column>Date</Table.Column>
              <Table.Column>Description</Table.Column>
              <Table.Column align={"right"}>Montant</Table.Column>
              <Table.Column align={"center"}>Bien</Table.Column>
              <Table.Column align={"center"}>Locataire</Table.Column>
              <Table.Column>Action</Table.Column>
            </Table.Header>
            <Table.Body>
              {sanitizedTransactions.map((transactionOrBalance, i) => {
                const isTransaction = !transactionOrBalance.id_transaction;
                const isSplit =
                  isTransaction &&
                  (transactionOrBalance.balances.length > 1 ||
                    (transactionOrBalance.balances.length === 1 &&
                      transactionOrBalance.balances[0].montant !==
                        transactionOrBalance.montant));
                const isAffected =
                  (isTransaction &&
                    transactionOrBalance.balances.length === 1 &&
                    transactionOrBalance.balances[0].montant ===
                      transactionOrBalance.montant) ||
                  (!isTransaction &&
                    (transactionOrBalance.id_tenant ||
                      transactionOrBalance.id_bien ||
                      transactionOrBalance.id_property_folder));
                const affectedBalance =
                  (isTransaction &&
                    isAffected &&
                    transactionOrBalance.balances[0]) ||
                  transactionOrBalance;

                let affectTenantButtonContent = <Plus />;
                let affectPropertyButtonContent = <Plus />;

                if (isAffected) {
                  if (affectedBalance.domaine === BalanceDomain.PROPERTY) {
                    affectPropertyButtonContent = properties.find(
                      (prop) => prop.id === affectedBalance.id_bien,
                    )?.nom;
                  } else if (affectedBalance.domaine === BalanceDomain.FOLDER) {
                    affectPropertyButtonContent = propertyFolders.find(
                      (folder) =>
                        folder.id === affectedBalance.id_property_folder,
                    )?.nom;
                  } else if (affectedBalance.domaine === BalanceDomain.TENANT) {
                    affectTenantButtonContent = tenantLogo(
                      affectedBalance.id_tenant,
                    );
                  }
                }

                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {isTransaction && (
                        <Selectable.Checkbox
                          value={transactionOrBalance.id}
                          index={i}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {isTransaction &&
                        dateConvert(transactionOrBalance.date_transac)}
                    </Table.Cell>
                    <Table.Cell>
                      {isTransaction
                        ? transactionOrBalance.description
                        : transactionOrBalance.comment}
                    </Table.Cell>
                    <Table.Cell align={"right"}>
                      {currencyFormatter(transactionOrBalance.montant)}
                    </Table.Cell>
                    <Table.Cell align={"center"}>
                      {isTransaction && !isSplit && (
                        <ButtonCard
                          onClick={() => {
                            openSelectPropertyModal();
                            setTransaction(transactionOrBalance);
                          }}
                          padding={"xs"}
                          border={"none"}
                          className={"secondaryText"}
                        >
                          {affectPropertyButtonContent}
                        </ButtonCard>
                      )}
                    </Table.Cell>
                    <Table.Cell align={"center"}>
                      {isTransaction && !isSplit && (
                        <ButtonCard
                          onClick={() => {
                            openSelectTenantModal();
                            setTransaction(transactionOrBalance);
                          }}
                          padding={"xs"}
                          border={"none"}
                          className={"secondaryText"}
                        >
                          {affectTenantButtonContent}
                        </ButtonCard>
                      )}
                    </Table.Cell>
                    <Table.Cell align={"center"}>
                      {isTransaction && (
                        <Dropdown
                          header={<DotsAction />}
                          type={"click"}
                          body={
                            <div
                              onClick={async () => {
                                setTransaction(transactionOrBalance);
                                openSplitModal();
                              }}
                              className={"secondaryText"}
                            >
                              Découper
                            </div>
                          }
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <div className={"flex wrap marginT20"}>
            <div className="marginB10 marginR10">
              <Selectable.ActionButton
                disabled={isBatchLoading}
                isLoading={isBatchLoading}
                resetOnClick
                onClick={async (selectedItems) => {
                  try {
                    setIsBatchLoading(true);
                    await deleteMultipleTransactions(selectedItems);
                  } catch (e) {
                    console.error(e);
                  }
                  setIsBatchLoading(false);
                }}
              >
                Supprimer
              </Selectable.ActionButton>
            </div>
            <Selectable.Action
              render={(selectedItems) => (
                <div className={"flex wrap gap10"}>
                  <SelectStandalone
                    className={"marginB10"}
                    disabled={isBatchLoading}
                    onChange={(event) =>
                      handleMultipleChange(event, selectedItems)
                    }
                  >
                    <option value="">Affecter à un bien ou dossier</option>
                    <option value="null">Supprimer l'affectation</option>
                    <option disabled>=== Dossiers ===</option>
                    {propertyFolders.map((folder, i) => (
                      <option
                        key={i}
                        value={`${BalanceDomain.FOLDER}|${folder.id}`}
                      >
                        {folder.nom}
                      </option>
                    ))}
                    <option disabled>=== Biens ===</option>
                    {properties.map((property, i) => (
                      <option
                        key={i}
                        value={`${BalanceDomain.PROPERTY}|${property.id}`}
                      >
                        {property.nom}
                      </option>
                    ))}
                  </SelectStandalone>
                  <SelectStandalone
                    className={"marginB10"}
                    disabled={isBatchLoading}
                    onChange={(event) =>
                      handleMultipleChange(event, selectedItems)
                    }
                  >
                    <option value="">Affecter à un locataire</option>
                    <option value="null">Supprimer l'affectation</option>
                    {tenants
                      .filter(
                        (tenant) => tenant.status === TenantStatus.Actif.status,
                      )
                      .map((tenant, i) => (
                        <option
                          key={i}
                          value={`${BalanceDomain.TENANT}|${tenant.id}`}
                        >
                          {tenant.profile_nom.nom_profile}
                        </option>
                      ))}
                    <option disabled>=== Locataires archivés ===</option>
                    {tenants
                      .filter(
                        (tenant) =>
                          tenant.status === TenantStatus.Archive.status,
                      )
                      .map((tenant, i) => (
                        <option
                          key={i}
                          value={`${BalanceDomain.TENANT}|${tenant.id}`}
                        >
                          {tenant.profile_nom.nom_profile}
                        </option>
                      ))}
                  </SelectStandalone>
                </div>
              )}
            />
          </div>
          <div className="text-right">
            <Button onClick={() => setPageIndex(pageIndex - 1)}>
              Précédent
            </Button>
            <Button
              onClick={() => setPageIndex(pageIndex + 1)}
              className={"marginL10"}
            >
              Suivant
            </Button>
          </div>
        </Selectable>
      </Card>
    </>
  );
}

export default TransactionList;
