import Card from "../UI/Card";
import { Table } from "../UI/Table";
import { currencyFormatter, dateConvert } from "../../api/Functions";
import useFactures from "../../hooks/use-factures";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import { DocType } from "../../utils/constants/documents";
import { PdfFile } from "../UI/Icons";

export default function Factures() {
  const { factures } = useFactures();
  return (
    <Card>
      <Table>
        <Table.Header>
          <Table.Column>Date</Table.Column>
          <Table.Column>Montant</Table.Column>
          <Table.Column>Document</Table.Column>
        </Table.Header>
        <Table.Body>
          {factures?.map((facture, i) => (
            <Table.Row key={i}>
              <Table.Cell>{dateConvert(facture.date_add)}</Table.Cell>
              <Table.Cell>{currencyFormatter(facture.montant)}</Table.Cell>
              <Table.Cell>
                <DocumentDownloadLink
                  documentId={facture.id}
                  doctype={DocType.FACTURE}
                  file={facture.file}
                >
                  <PdfFile />
                </DocumentDownloadLink>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Card>
  );
}
