import { useId } from "react";

export default function RadioStandalone({ label, ...props }) {
  const id = useId();

  return (
    <span>
      <input {...props} type="radio" id={id} />
      {label && <label htmlFor={id}>{label}</label>}
    </span>
  );
}
