import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { deleteDraft } from "../../api/Draft";

import {
  folderSchema,
  PROPERTY_INITIAL_VALUES,
  propertySchema,
} from "../../models/property";
import useProperties from "../../hooks/use-properties";
import Finance from "./AddFormComponents/Finance";
import EquipementsLocal from "./AddFormComponents/EquipementsLocal";
import HeaderPropertyForm from "./AddFormComponents/HeaderPropertyForm";
import { sanitizeValues, toInitialValuesFromSchema } from "../../models/utils";
import { useModal } from "../../hooks/use-modal";
import useAuth from "../../hooks/use-auth";
import TabbedForm from "../UI/TabbedForm";
import { handleAPIError } from "../../utils";
import TypeBienForm from "./AddFormComponents/TypeBienForm";
import TypeLogement from "./AddFormComponents/TypeLogement";
import TypeLocation from "./AddFormComponents/TypeLocation";
import usePropertyFolders from "../../hooks/use-property-folders";
import AddFolder from "./AddFormComponents/AddFolder";

function PropertyForm({
  drafts,
  profiles,
  edit,
  isDraft,
  draftSavedHandler,
  draftDeletedHandler,
}) {
  const { auth } = useAuth();
  const { propertyId, id_draft } = useParams();
  const [idDraft, setIdDraft] = useState(id_draft);
  const [error, setError] = useState("");
  const [typeBien, setTypeBien] = useState("property");
  const { properties, addProperty, updateProperty } = useProperties();
  const { createPropertyFolder } = usePropertyFolders();

  const [Modal, closeModal] = useModal(true);
  const formRef = useRef();

  let initialValues = PROPERTY_INITIAL_VALUES;

  if (edit) {
    let property;

    if (isDraft) {
      const draft = drafts.find((b) => b.id === parseInt(idDraft));
      if (!draft) {
        return null;
      }
      property = draft.json;
    } else {
      property = properties.find((b) => b.id === parseInt(propertyId));
    }
    initialValues = toInitialValuesFromSchema(
      propertySchema,
      property,
      PROPERTY_INITIAL_VALUES,
    );
  }

  async function submitHandler(values, { setSubmitting }) {
    const property = { ...sanitizeValues(values) };
    try {
      if (property.type === "folder") {
        await createPropertyFolder(property);
      } else {
        if (edit && !isDraft) {
          await updateProperty(parseInt(propertyId), property);
        } else {
          await addProperty(property);
        }

        if (isDraft && idDraft) {
          try {
            await deleteDraft(auth.accessToken, idDraft);
            draftDeletedHandler(idDraft);
          } catch (error) {
            console.error(error);
          }
        }
      }
      closeModal();
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={submitHandler}
        validationSchema={
          typeBien === "property" ? propertySchema : folderSchema
        }
      >
        {(formikProps) => {
          let tabIndex = 0;
          return (
            <>
              <TabbedForm
                draftType={2}
                onDraftSaved={draftSavedHandler}
                formikProps={formikProps}
                draftId={idDraft}
                setDraftId={setIdDraft}
                isDraft={isDraft}
                id="property-form"
                ref={formRef}
              >
                <Modal
                  confirmCloseText={
                    "Votre formulaire n'a pas été sauvegardé. Voulez-vous vraiment quitter ?"
                  }
                  shouldConfirm={() => formikProps.dirty}
                  size={"big"}
                  stickyHeader
                  blueHeader
                  onClose={() => {
                    formRef.current?.resetTab();
                  }}
                  header={
                    <TabbedForm.TabList>
                      <TabbedForm.Tab panelId="type-bien" index={tabIndex++}>
                        Type de bien
                      </TabbedForm.Tab>
                      {formikProps.values.type && (
                        <>
                          {formikProps.values.type === "habitation" && (
                            <>
                              <TabbedForm.Tab
                                panelId="type-logement"
                                index={tabIndex++}
                              >
                                Type de logement
                              </TabbedForm.Tab>
                              <TabbedForm.Tab
                                panelId="type-locataire"
                                index={tabIndex++}
                              >
                                Type de locataire
                              </TabbedForm.Tab>
                            </>
                          )}

                          {formikProps.values.type === "folder" ? (
                            <TabbedForm.Tab
                              panelId="add-folder"
                              index={tabIndex++}
                            >
                              Dossier
                            </TabbedForm.Tab>
                          ) : (
                            <>
                              <TabbedForm.Tab panelId="bien" index={tabIndex++}>
                                Le bien
                              </TabbedForm.Tab>
                              <TabbedForm.Tab
                                panelId="finance-fiscalite"
                                index={tabIndex++}
                              >
                                Finance et fiscalité
                              </TabbedForm.Tab>
                              <TabbedForm.Tab
                                panelId="equipements"
                                index={tabIndex++}
                              >
                                Equipements
                              </TabbedForm.Tab>
                            </>
                          )}
                        </>
                      )}
                    </TabbedForm.TabList>
                  }
                >
                  <Form>
                    <div className="marginT10">
                      <TabbedForm.TabPanel id="type-bien">
                        <TypeBienForm
                          setFieldValue={formikProps.setFieldValue}
                          setTypeBien={setTypeBien}
                        />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="type-logement">
                        <TypeLogement
                          setFieldValue={formikProps.setFieldValue}
                        />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="type-locataire">
                        <TypeLocation
                          setFieldValue={formikProps.setFieldValue}
                        />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="bien">
                        <HeaderPropertyForm profiles={profiles} />
                        <TabbedForm.Actions error={error} />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="finance-fiscalite">
                        <Finance
                          values={formikProps.values}
                          setFieldValue={formikProps.setFieldValue}
                        />
                        <TabbedForm.Actions error={error} />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="equipements">
                        <EquipementsLocal propertyId={propertyId} />
                        <TabbedForm.Actions error={error} />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="add-folder">
                        <AddFolder profiles={profiles} />
                      </TabbedForm.TabPanel>
                    </div>
                  </Form>
                </Modal>
              </TabbedForm>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default PropertyForm;
