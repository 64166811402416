import Card from "../UI/Card";
import useAccount from "../../hooks/use-account";
import Button from "../UI/Button";
import { useModal } from "../../hooks/use-modal";
import ChangePasswordForm from "./ChangePasswordForm";
import moment from "moment";
import { Croix, Edit, Interrogation } from "../UI/Icons";
import Tooltip from "../UI/Tooltip";
import { currencyFormatter } from "../../api/Functions";
import BuyCredits from "../Stripe/BuyCredits";
import useStripePaymentMethods from "../../hooks/use-stripe-payment-methods";
import useStripePayment from "../Stripe/Stripe";
import PaymentMethod from "../Stripe/PaymentMethod";
import AddressFacturation from "./AddressFacturation";
import useLoader from "../../hooks/use-loader";
import BuySubscriptionForm from "../Stripe/BuySubscriptionForm";
import CopyToClipboard from "../UI/CopyToClipboard";
import { TextOneOrMany } from "../../utils";
import SignaturePad from "../UI/SignaturePad";
import useLogo from "../../hooks/use-logo";
import useAuthenticatedAxios from "../../hooks/use-authenticated-axios";
import Img from "../UI/Img";
import useSignature from "../../hooks/use-signature";
import styles from "./MonCompte.module.css";
import ButtonAction from "../UI/ButtonAction";
import FileInput from "../UI/FileInput";
import { Form, Formik } from "formik";
import { DocType } from "../../utils/constants/documents";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import { array, object } from "yup";
import { useStripeAbonnementPrice } from "../../hooks/use-stripe-prices";
import { TypeAbonnement } from "../../utils/constants/account";
import { monthsToRecurrence } from "../../utils/abonnement";
import Spinner from "../UI/Spinner";

export default function MonCompte() {
  const {
    account,
    filleuls,
    refresh: refreshAccount,
    changePassword,
    unsubscribe,
    enableTrial,
  } = useAccount();

  const {
    paymentMethods,
    defaultPaymentMethod,
    deletePaymentMethod,
    markDefaultPaymentMethod,
    refresh: refreshPaymentMethods,
  } = useStripePaymentMethods();
  const { initiateSetup } = useStripePayment();
  const { logo, logoBlob, createLogo, deleteLogo } = useLogo(true);
  const { signature, signatureBlob, createSignature, deleteSignature } =
    useSignature(true);
  const [ChangePassword, closeChangePassword, openChangePassword] = useModal();
  const [SignatureModal, closeSignatureModal, openSignatureModal] = useModal();
  const [LogoModal, closeLogoModal, openLogoModal] = useModal();
  const [AccountBuyCredits, , openAccountBuyCredits] = useModal();
  const [SubscribeModal, closeSubscribeModal, openSubscribeModal] = useModal();
  const axios = useAuthenticatedAxios();
  const [unsubscribing, unsubscribeLoader] = useLoader(async () => {
    try {
      await unsubscribe({ autorenew_abonnement: false });
    } catch (e) {
      console.error(e);
    }
  });
  const [activatingTrial, activateTrial] = useLoader(enableTrial);

  const referralLink = `${process.env.REACT_APP_FRONTEND_URL}/signup?referral=${account.id_parrainage}`;

  function dataURLToBlob(dataURL) {
    const bytes = Uint8Array.from(atob(dataURL.split(",")[1]), (c) =>
      c.charCodeAt(0),
    );
    return new Blob([bytes], { type: "image/png" });
  }

  async function saveSignatureHandler(sigCanvas) {
    const formData = new FormData();
    const blob = dataURLToBlob(sigCanvas.toDataURL("image/png"));
    formData.append("documents", blob, "signature.png");
    try {
      const res = await axios.post("/documents/upload", formData, {
        params: { doctype: DocType.SIGNATURE },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await createSignature({ file: res.data[0] });
      closeSignatureModal();
    } catch (error) {
      console.error(error);
    }
  }

  async function saveLogoHandler({ files }) {
    try {
      await createLogo({ file: files[0] });
      closeLogoModal();
    } catch (error) {
      console.error(error);
    }
  }

  const isAbonne =
    (account.expiration_abonnement === null ||
      moment(account.expiration_abonnement).isAfter(moment())) &&
    account.type_abonnement !== TypeAbonnement.GRATUIT;

  return (
    <>
      <SignatureModal>
        <SignaturePad onSave={saveSignatureHandler} />
      </SignatureModal>
      <LogoModal>
        <Formik
          initialValues={{ files: [] }}
          onSubmit={saveLogoHandler}
          validationSchema={object({
            files: array().of(object()).length(1, "Un logo est requis"),
          })}
        >
          <Form>
            <FileInput
              documentId={logo?.id}
              doctype={DocType.LOGO}
              label="Sélectionnez votre logo"
              format={"Format PNG, JPG, JPEG"}
              accept=".png,.jpg,.jpeg"
            />
            <FormikSubmitButton>Enregistrer</FormikSubmitButton>
          </Form>
        </Formik>
      </LogoModal>
      <ChangePassword>
        <ChangePasswordForm
          onSubmit={async (values) => {
            await changePassword(values);
            closeChangePassword();
          }}
        />
      </ChangePassword>
      <AccountBuyCredits size={"medium"}>
        <BuyCredits
          account={account}
          refreshAccount={refreshAccount}
          buyCredit
        />
      </AccountBuyCredits>
      <BuySubscriptionForm
        onSubscribed={closeSubscribeModal}
        Modal={SubscribeModal}
      />
      <Card>
        <div className={"flex space-between wrap gap10"}>
          <p className={"cardTitle"}>Informations du compte</p>
          <Button buttonStyle={"secondary"} onClick={openChangePassword}>
            Modifier le mot de passe
          </Button>
        </div>
        <p className={"marginT10"}>
          <span className={"primaryText"}>Mail de connexion : </span>
          {account.mail}
        </p>
        <p className={"marginT10"}>
          <span className={"primaryText"}>Type d'abonnement : </span>
          {isAbonne ? (
            <AbonnementType
              type={account.type_abonnement}
              nbBiens={account.nb_biens_abonnement}
              stripePriceId={account.stripe_price_abonnement}
            />
          ) : (
            <AbonnementType type={TypeAbonnement.GRATUIT} nbBiens={1} />
          )}
          {account.type_abonnement_apres_expiration && (
            <>
              {" "}
              puis{" "}
              <AbonnementType
                type={account.type_abonnement_apres_expiration}
                nbBiens={account.nb_biens_abonnement_apres_expiration}
                stripePriceId={account.stripe_price_abonnement_apres_expiration}
              />
            </>
          )}
          <Button
            buttonStyle={"link"}
            className={"marginL10"}
            onClick={openSubscribeModal}
          >
            {isAbonne ? "Changer d'abonnement" : "S'abonner"}
          </Button>
          {account.available_trial > 0 && !account.type_abonnement && (
            <Button
              buttonStyle={"link"}
              className={"marginL10"}
              onClick={activateTrial}
              isLoading={activatingTrial}
            >
              Activer l'essai gratuit de {account.available_trial} jours.
            </Button>
          )}
        </p>
        <p className={"marginT10"}>
          <span className={"primaryText"}>
            {account.expiration_abonnement && account.autorenew_abonnement
              ? "Prochain paiement"
              : "Date d'expiration"}{" "}
            :{" "}
          </span>
          {isAbonne && account.expiration_abonnement
            ? moment(account.expiration_abonnement).format("DD/MM/YYYY")
            : "jamais"}
          {account.autorenew_abonnement && (
            <Button
              buttonStyle={"link"}
              className={"marginL10"}
              isLoading={unsubscribing}
              onClick={unsubscribeLoader}
            >
              Annuler l'abonnement
            </Button>
          )}
        </p>
        <p className={"marginT10"}>
          <span className={"primaryText"}>
            Nombre de crédits disponibles{" "}
            <Tooltip
              content={
                "Utilisés pour les signatures électroniques et lettres recommandées"
              }
            >
              <Interrogation />
            </Tooltip>{" "}
            :{" "}
          </span>
          {currencyFormatter(account.credits)}
          <Button
            buttonStyle={"link"}
            className={"marginL10"}
            onClick={openAccountBuyCredits}
          >
            Acheter des crédits
          </Button>
        </p>
        <p className={"marginT10"}>
          <span className="primaryText">
            Cartes de paiement enregistrées :{" "}
          </span>
          <Button
            buttonStyle={"link"}
            className={"marginL10"}
            onClick={() =>
              initiateSetup({ onSetupSuccessful: refreshPaymentMethods })
            }
          >
            Ajouter une carte de paiement
          </Button>
        </p>
        {paymentMethods.length === 0 && (
          <p className={"marginT10 paddingL10"}>Aucune carte enregistrée</p>
        )}
        <div className={"gridColWrap240 marginT20"}>
          {paymentMethods.length > 0 &&
            paymentMethods.map((method, i) => (
              <PaymentMethod
                method={method}
                isDefault={defaultPaymentMethod === method.id}
                onDelete={deletePaymentMethod}
                onMarkDefault={markDefaultPaymentMethod}
                key={i}
              />
            ))}
        </div>
        <div>
          <span className={"primaryText"}>Lien de parrainage </span>(
          {filleuls?.nb_comptes}{" "}
          <TextOneOrMany
            one={"filleul"}
            many={"filleuls"}
            number={filleuls?.nb_comptes}
          />
          ) : {referralLink} <CopyToClipboard toCopy={referralLink} />
        </div>
        <div className={"marginT10"}>
          <span className={"primaryText marginR10"}>
            Signature sur les documents{" "}
            <Tooltip
              content={
                "Dessinez votre signature, celle-ci sera intégré aux documents que vous envoyez à votre locataire."
              }
            >
              <Interrogation />
            </Tooltip>
          </span>
          <ButtonAction
            onClick={openSignatureModal}
            logo={<Edit />}
            tooltip={"Editer la signature"}
            className={"marginR10"}
          />
          {signatureBlob && (
            <ButtonAction
              onClick={() => deleteSignature(signature.id)}
              logo={<Croix color={"red"} size={"20"} />}
              tooltip={"Supprimer le logo"}
            />
          )}
          <div className="flex">
            {signatureBlob && (
              <Img
                blob={signatureBlob}
                alt="signature"
                className={styles.bgWhite}
              />
            )}
            {!signatureBlob && (
              <p className={"marginT10 paddingL10"}>
                Pas de signature enregistrée
              </p>
            )}
          </div>
        </div>
        <div className={"marginT10"}>
          <span className={"primaryText marginR10"}>
            Logo personnalisé sur les documents{" "}
            <Tooltip
              content={
                "Le logo enregistré ici apparaîtra sur tous les documents générés. Si aucun logo n'est renseigné, le logo Qalimo apparaîtra."
              }
            >
              <Interrogation />
            </Tooltip>
          </span>
          <ButtonAction
            onClick={openLogoModal}
            logo={<Edit />}
            tooltip={"Modifier le logo"}
            className={"marginR10"}
          />
          {logoBlob && (
            <ButtonAction
              onClick={() => deleteLogo(logo.id)}
              logo={<Croix color={"red"} size={"20"} />}
              tooltip={"Supprimer le logo"}
            />
          )}
          {!logoBlob && (
            <p className={"marginT10 paddingL10"}>Pas de logo enregistré</p>
          )}
          <div className="flex">
            {logoBlob && <Img blob={logoBlob} alt="logo" />}
          </div>
        </div>
      </Card>
      <AddressFacturation />
    </>
  );
}

export function AbonnementType({ type, nbBiens, stripePriceId }) {
  const { price, isLoading } = useStripeAbonnementPrice(stripePriceId, nbBiens);
  if (isLoading) return <Spinner />;
  let rate = "";
  if (price) {
    rate = `${currencyFormatter(price.unit_amount / 100)} ${monthsToRecurrence(
      price.months,
    )}`;
  }
  switch (type) {
    case TypeAbonnement.EARLYBIRD:
      return `Early Bird - biens illimités - ${rate}`;
    case TypeAbonnement.REGULAR:
      if (nbBiens >= 7) {
        return `Rentier - biens illimités - ${rate}`;
      }
      return `Investisseur - ${nbBiens} biens - ${rate}`;
    case TypeAbonnement.GRATUIT:
      return `Gratteur - 1 bien - gratuit`;
    case TypeAbonnement.TRIAL:
      return `Essai gratuit - biens illimités`;
    default:
      return "Aucun";
  }
}
