import useSWR from "swr";
import useAuthenticatedAxios from "./use-authenticated-axios";
import useAuth from "./use-auth";
import { findAndUpdate } from "../utils";
import { useMemo } from "react";

export default function useResources(rootPath, options = {}) {
  const axios = useAuthenticatedAxios();
  const fallbackData = useMemo(() => [], []);
  const { auth } = useAuth();
  const {
    data: resources,
    error,
    isLoading,
    mutate,
  } = useSWR(
    rootPath ? { url: rootPath, options, userId: auth.userId } : null,
    ({ url, options }) => {
      return axios.get(url, { params: options.params }).then((res) => res.data);
    },
    { fallbackData },
  );

  async function create(resource, options = {}) {
    let newResource;
    const { alternateRootPath, sort } = options;
    await mutate(
      async (current) => {
        const response = await axios.post(
          alternateRootPath ?? rootPath,
          resource,
        );
        newResource = response.data;
        const newResources = [...current, newResource];
        if (sort) {
          newResources.sort(sort);
        }
        return newResources;
      },
      { revalidate: false },
    );
    return newResource;
  }

  function del(resourceId, options = {}) {
    const { alternateRootPath } = options;
    return mutate(
      async (current) => {
        await axios.delete(`${alternateRootPath ?? rootPath}/${resourceId}`);
        return current.filter((resource) => resource.id !== resourceId);
      },
      { revalidate: false },
    );
  }

  async function update(resourceId, resource, options = {}) {
    const { alternateRootPath, sort } = options;
    let newResource;
    await mutate(
      async (current) => {
        const response = await axios.put(
          `${alternateRootPath ?? rootPath}/${resourceId}`,
          resource,
        );
        newResource = response.data;
        const newResources = findAndUpdate(
          current,
          (resource) => resource.id === resourceId,
          newResource,
        );
        if (sort) {
          newResources.sort(sort);
        }
        return newResources;
      },
      { revalidate: false },
    );
    return newResource;
  }

  return {
    resources,
    error,
    isLoading,
    create,
    update,
    del,
    mutate,
    axios,
  };
}
