import { useField } from "formik";

import useDependentField, {
  usePropertyExtractor,
} from "../../hooks/use-dependent-field";
import { classnames } from "../../utils";
import TextInputStandalone from "./TextInputStandalone";

export default function TextInput({
  className,
  marginForm = "margin-form",
  ...props
}) {
  const [field, meta] = useField(props);

  useDependentField(
    props.name,
    props.dependon,
    usePropertyExtractor(props.name),
  );

  return (
    <TextInputStandalone
      className={classnames(className, marginForm)}
      error={meta.touched && meta.error}
      {...field}
      {...props}
    />
  );
}
