import TextInput from "../UI/TextInput";
import FormUpload from "../File/FormUpload";

function ProfileFormDom({ isBailleur, prefix = "", isMandataire }) {
  return (
    <>
      {!isMandataire && <h3>Adresse actuelle</h3>}

      <div>
        <TextInput
          type="text"
          name={`${prefix}adresse`}
          placeholder="Adresse"
          label="Adresse"
          mandatory
        />
        <TextInput
          name={"complement_adresse"}
          label={"Complément d'adresse"}
          placeholder={"Batiment B"}
        />
        <div className={"flex gap10"}>
          <TextInput
            type="text"
            name={`${prefix}cp`}
            placeholder="Code postal"
            label="Code postal"
            mandatory
          />
          <TextInput
            type="text"
            name={`${prefix}ville`}
            placeholder="Ville"
            label="Ville"
            mandatory
            className={"flex-grow"}
          />
        </div>
        <TextInput
          type="text"
          name={`${prefix}pays`}
          placeholder="Pays"
          label="Pays"
          mandatory
        />

        {!isBailleur && !isMandataire && (
          <>
            Justificatif de domicile
            <FormUpload tag="domicile" />
          </>
        )}
      </div>
    </>
  );
}

export default ProfileFormDom;
