import Button from "../UI/Button";
import useTenants from "../../hooks/use-tenants";
import { useModal } from "../../hooks/use-modal";
import FinanceConfigForm from "./FinanceConfigForm";
import { Fragment, useState } from "react";
import useFinanceConfigs from "../../hooks/use-finance-configs";
import Card from "../UI/Card";
import style from "./FinanceConfigList.module.css";
import IconAndText from "../UI/IconAndText";
import { Ciseaux, Edit, TagPrice } from "../UI/Icons";
import { Table } from "../UI/Table";
import { classnames } from "../../utils";
import useFinanceSplitTemplates from "../../hooks/use-finance-split-templates";
import FinanceConfigDecoupeForm from "./FinanceConfigDecoupeForm";
import useProperties from "../../hooks/use-properties";
import { sanitizeValues } from "../../models/utils";
import { currencyFormatter } from "../../api/Functions";
import useProfileNoms from "../../hooks/use-profile-noms";
import { Link } from "react-router-dom";
import ActionList from "../Actions/ActionList";

function FinanceConfigList({ displayNom, tenantId }) {
  const { tenants } = useTenants();
  const { properties } = useProperties();
  const { profileNoms } = useProfileNoms();
  const [FormModal, closeFormModal, openFormModal] = useModal();
  const [FormDecoupeModal, closeFormDecoupeModal, openFormDecoupeModal] =
    useModal();
  const [configToEdit, setConfigToEdit] = useState(null);
  const [splitTemplateToEdit, setSplitTemplateToEdit] = useState(null);

  const {
    financeConfigs,
    deleteFinanceConfig,
    updateFinanceConfig,
    addFinanceConfig,
  } = useFinanceConfigs(tenantId);
  const {
    splitTemplates,
    updateFinanceSplitTemplate,
    addFinanceSplitTemplate,
    deleteFinanceSplitTemplate,
  } = useFinanceSplitTemplates();

  async function deleteFinanceConfigHandler(configId) {
    try {
      await deleteFinanceConfig(configId);
      closeFormModal();
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteFinanceSplitTemplateHandler(configId) {
    try {
      await deleteFinanceSplitTemplate(configId);
      closeFormDecoupeModal();
    } catch (e) {
      console.error(e);
    }
  }

  async function submitSplitTemplateHandler(values, { resetForm }) {
    const sanitizedValues = sanitizeValues(values);
    try {
      if (sanitizedValues.id) {
        await updateFinanceSplitTemplate(sanitizedValues.id, sanitizedValues);
      } else {
        await addFinanceSplitTemplate(sanitizedValues);
      }
      closeFormDecoupeModal();
      setSplitTemplateToEdit(null);
      resetForm();
    } catch (e) {
      console.error(e);
    }
  }

  async function submitFinanceConfigHandler(values, { resetForm }) {
    const sanitizedValues = sanitizeValues(values);
    try {
      if (sanitizedValues.id) {
        await updateFinanceConfig(sanitizedValues.id, sanitizedValues);
      } else {
        await addFinanceConfig(sanitizedValues);
      }
      closeFormModal();
      setConfigToEdit(null);
      resetForm();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <FormModal>
        <FinanceConfigForm
          config={configToEdit}
          tenantId={tenantId}
          onDelete={deleteFinanceConfigHandler}
          onSubmit={submitFinanceConfigHandler}
        />
      </FormModal>
      <FormDecoupeModal>
        <FinanceConfigDecoupeForm
          config={splitTemplateToEdit}
          onDelete={deleteFinanceSplitTemplateHandler}
          onSubmit={submitSplitTemplateHandler}
        />
      </FormDecoupeModal>
      <div className={style.grid}>
        <Card className={classnames(!tenantId && style.configPanel)}>
          <p className={"cardTitle"}>Ajouter un mot clé</p>
          <img
            src={`/photo_finance_config.png`}
            alt="Finance config"
            className={"center"}
          />
          <div>
            <p className={"text-center marginT20"}>
              Si vous ne l’avez pas encore fait, connectez votre compte bancaire{" "}
              <Link to={"/bailleur/finances/add"}>ici</Link>
            </p>
            <p className={"marginT20 text-center"}>
              Si Qalimo ne parvient pas à identifier les virements de votre
              locataire, cela peut provenir d’un libellé de virement différent
              du nom du locataire (paiement effectué par le conjoint, nom de
              jeune fille, employeur, ami, compte professionnel, CAF, aides
              sociales, etc ..).
            </p>
            <p className={"marginT20 text-center"}>
              Vous pouvez alors créer une règle pour synchroniser un virement
              reçu à l’aide d’un mot clé qui figure sur votre relevé bancaire !
            </p>
          </div>
          <Button
            onClick={() => {
              setConfigToEdit(null);
              openFormModal();
            }}
            className={"center marginT20"}
          >
            Ajouter un mot clé
          </Button>
          <Card
            className={classnames(style.greyBackground, "marginT20 padding10")}
          >
            <IconAndText
              icon={<TagPrice />}
              text={
                <span className={"secondaryText"}>
                  Liste des mots clés actifs
                </span>
              }
            />
            {profileNoms
              .filter((profile) =>
                financeConfigs?.find(
                  (filtered) => filtered.id_profile_nom === profile.id,
                ),
              )
              .map((profile, i) => (
                <Fragment key={i}>
                  {!tenantId && (
                    <p className={"marginT10 primaryText"}>
                      {profile.nom_profile} :
                    </p>
                  )}
                  <Card className={classnames("marginT10 secondaryText")}>
                    <Table>
                      <Table.Header>
                        {displayNom && <Table.Column>Locataires</Table.Column>}
                        <Table.Column>Mots clés</Table.Column>
                        <Table.Column>Editer</Table.Column>
                      </Table.Header>
                      <Table.Body>
                        {financeConfigs
                          ?.filter(
                            (filtered) =>
                              filtered.id_profile_nom === profile.id,
                          )
                          .map((config, i) => (
                            <Table.Row key={i}>
                              {displayNom && (
                                <Table.Cell>
                                  {
                                    tenants?.find(
                                      (tenant) =>
                                        tenant.id === config.id_tenant,
                                    )?.profile_nom.nom_profile
                                  }
                                </Table.Cell>
                              )}

                              <Table.Cell>{config.keyword}</Table.Cell>
                              <Table.Cell className={"text-center"}>
                                <span
                                  onClick={() => {
                                    setConfigToEdit(config);
                                    openFormModal();
                                  }}
                                  className={"cursorPointer"}
                                >
                                  <Edit />
                                </span>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </Card>
                </Fragment>
              ))}
          </Card>
        </Card>
        {tenantId ? (
          <ActionList
            tenant={tenants?.find((filtered) => filtered.id === tenantId)}
          />
        ) : (
          <Card className={style.configPanel}>
            <p className={"cardTitle"}>Découper les virements de la CAF</p>
            <img
              src={`/photo_finance_config_caf.png`}
              alt="Finance config"
              className={"center"}
            />
            <div>
              <p className={"text-center marginT20"}>
                Vous recevez un virement unique de la Caisse d’Allocations
                Familiales qui concerne plusieurs locataires ?
              </p>
              <p className={"text-center marginT20"}>
                Dispatchez le virement reçu et créez une règle de partage entre
                les locataires concernés !
              </p>
              <p className={"text-center marginT20"}>
                Vous n’aurez plus besoin de saisir manuellement le montant perçu
                pour chaque locataire.
              </p>
            </div>

            <Button
              onClick={() => {
                setSplitTemplateToEdit(null);
                openFormDecoupeModal();
              }}
              className={"center marginT20"}
            >
              Ajouter une règle de découpe
            </Button>
            <Card
              className={classnames(
                style.greyBackground,
                "marginT20 padding10",
              )}
            >
              <IconAndText
                icon={<Ciseaux />}
                text={
                  <span className={"secondaryText"}>
                    Liste des règles de découpe
                  </span>
                }
              />
              {profileNoms
                .filter((profile) =>
                  splitTemplates?.find(
                    (filtered) => filtered.id_profile_nom === profile.id,
                  ),
                )
                .map((profile, i) => (
                  <Fragment key={i}>
                    {!tenantId && (
                      <p className={"marginT10 primaryText"}>
                        {profile.nom_profile} :
                      </p>
                    )}
                    {splitTemplates
                      ?.filter(
                        (splitTemplate) =>
                          splitTemplate.id_profile_nom === profile.id,
                      )
                      .map((template, i) => (
                        <Card
                          className={classnames("marginT10  secondaryText")}
                          key={i}
                        >
                          <div className={"flex space-between"}>
                            <p className={"cardTitle"}>{template.nom}</p>
                            <Button
                              type={"button"}
                              onClick={() => {
                                setSplitTemplateToEdit(template);
                                openFormDecoupeModal();
                              }}
                            >
                              Editer
                            </Button>
                          </div>
                          <Table className={"marginT10"}>
                            <Table.Header>
                              <Table.Column>Locataire</Table.Column>
                              <Table.Column>Bien</Table.Column>
                              <Table.Column>Montant</Table.Column>
                            </Table.Header>
                            <Table.Body>
                              {template?.splits?.map((rowTemplate, i) => (
                                <Table.Row key={i}>
                                  <Table.Cell>
                                    {
                                      tenants?.find(
                                        (tenant) =>
                                          tenant.id === rowTemplate.id_tenant,
                                      )?.profile_nom.nom_profile
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {
                                      properties?.find(
                                        (property) =>
                                          property.id === rowTemplate.id_bien,
                                      )?.nom
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {currencyFormatter(rowTemplate.montant)}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </Card>
                      ))}
                  </Fragment>
                ))}
            </Card>
          </Card>
        )}
      </div>
    </>
  );
}

export default FinanceConfigList;
