import useSWRImmutable from "swr/immutable";
import useAuthenticatedAxios from "./use-authenticated-axios";
import { useMemo } from "react";

export default function useStripePrices(type, options = {}) {
  const axios = useAuthenticatedAxios();
  const fallbackData = useMemo(() => [], []);
  const { params } = options;
  const {
    data: prices,
    error,
    isLoading,
  } = useSWRImmutable(
    ["/stripe/prices", type, params],
    ([url, type, params]) => {
      return axios
        .get(url, { params: { type, ...params } })
        .then((res) => res.data);
    },
  );

  return {
    prices: prices || fallbackData,
    error,
    isLoading,
  };
}

export function useStripeAbonnementPrice(priceId, nbBiens) {
  const axios = useAuthenticatedAxios();
  const { data, error, isLoading } = useSWRImmutable(
    priceId ? [priceId, nbBiens] : null,
    ([priceId, nbBiens]) => {
      return axios
        .get(`/stripe/abonnements/${priceId}`, {
          params: { nb_biens: nbBiens },
        })
        .then((res) => res.data);
    },
  );

  return {
    price: data,
    error,
    isLoading,
  };
}
