import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Select from "../../UI/Select";
import useProperties from "../../../hooks/use-properties";
import {
  ADD_TENANT_INITIAL_VALUE,
  convertTenantCivilSchema,
  convertTenantLCDSchema,
  convertTenantMobiliteSchema,
  convertTenantSchema,
  TenantStatus,
} from "../../../models/tenant";
import Card from "../../UI/Card";
import TenantConvertForm from "./TenantConvertForm";
import { Link, useNavigate } from "react-router-dom";
import ListFiles from "../../UI/ListFiles";
import { fetchFilesProperties } from "../../../api/Files";
import Radio from "../../UI/Radio";
import RadioGroup from "../../UI/RadioGroup";
import {
  sanitizeValues,
  toInitialValuesFromSchema,
} from "../../../models/utils";
import { produce } from "immer";
import PropertyDisplaySmallCard from "../../Property/PropertyDisplaySmallCard";
import useAuth from "../../../hooks/use-auth";
import TenantProspectForm from "./TenantProspectForm";
import useTenants from "../../../hooks/use-tenants";
import TabbedForm from "../../UI/TabbedForm";
import { handleAPIError, showFormikFieldsInError } from "../../../utils";
import useLeaseTemplates from "../../../hooks/use-lease-templates";
import useAuthenticatedAxios from "../../../hooks/use-authenticated-axios";
import ChooseSendFileMethod from "../../UI/ChooseSendFileMethod";
import TenantAddProfile from "../TenantAddProfile";
import { mandatairesFields, ProfileStatus } from "../../../models/profile";
import { GenLease, NoGenLease } from "../../UI/Icons";
import IconAndText from "../../UI/IconAndText";
import useMandataire from "../../../hooks/use-mandataire";

export class TenantAddFormType {
  static LOCATAIRE = "locataire";
  static CANDIDAT = "candidat";
}

export default function TenantAddForm({
  tenant,
  modal: ModalElement,
  openAddProfileModal,
  profileToPush,
  onAddProfile,
  onDeleteProfile,
  propertyId,
  closeTenantAddModal,
  type,
  regenerate,
}) {
  const { auth } = useAuth();
  const { mandataire } = useMandataire();
  const { properties } = useProperties();
  const { tenants, addTenant, createLease, updateTenant } = useTenants();
  const [files, setFiles] = useState([]);
  const [previewBail, setPreviewBail] = useState("");
  const { leaseTemplates } = useLeaseTemplates();
  const [error, setError] = useState("");
  const [lease, setLease] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const axios = useAuthenticatedAxios();
  const setFilesHandler = useCallback(
    async (id) => {
      setFiles(await fetchFilesProperties(auth.accessToken, id));
    },
    [auth.accessToken],
  );
  const nav = useNavigate();
  const formRef = useRef();
  let profilesIndiv = [];
  tenants?.forEach((tenant) =>
    tenant.profile_nom.profiles
      .filter((p) => p.status === ProfileStatus.Actif.status)
      .forEach((profile) =>
        profilesIndiv.push({
          profile: profile,
          tenant: {
            status: tenant.status,
            id_bien: tenant.id_bien,
            id: tenant.id,
          },
        }),
      ),
  );

  function getProfiles(values) {
    return values.map((value) => value.profile);
  }

  useEffect(() => {
    if (tenant) {
      setFilesHandler(tenant.id_bien);
    } else if (propertyId) {
      setFilesHandler(propertyId);
    }
  }, [tenant, auth.accessToken, setFilesHandler, propertyId]);

  const updateLease = useCallback(
    (leaseTemplateId) => {
      const leaseFound = leaseTemplates?.find(
        (leaseTemplate) => leaseTemplate.id === leaseTemplateId,
      );
      if (leaseFound) {
        setLease(leaseFound);
      }
    },
    [leaseTemplates],
  );

  const handleLeaseType = useCallback(
    (propertyId) => {
      const prop = properties.find((property) => property.id === propertyId);
      updateLease(prop?.id_lease_template);
      setPropertyType(prop?.type);
    },
    [properties, updateLease],
  );

  useEffect(() => {
    handleLeaseType(propertyId);
  }, [handleLeaseType, propertyId]);

  function defineProfileNom(profileToPush) {
    // Création du profile Nom. S'il n'y a qu'un profile, on met le prénom complet, sinon on met que la 1ère lettre
    return profileToPush
      .map((p) =>
        parseInt(p.profile.type) === 1
          ? `${
              profileToPush.length > 1 ? p.profile.prenom[0] : p.profile.prenom
            } ${p.profile.nom}`
          : p.profile.nom_societe,
      )
      .join(" & ");
  }

  async function submitHandler(values) {
    if (profileToPush.length === 0) {
      setError(
        "Veuillez sélectionner au moins un profil locataire avant de continuer",
      );
      return;
    }

    const sanitizedValues = { ...sanitizeValues(values) };
    const loyer_a_jour = sanitizedValues.loyer_a_jour;
    const typeEnvoi = sanitizedValues.type_envoi;
    delete sanitizedValues.type_envoi;
    delete sanitizedValues.loyer_a_jour;
    delete sanitizedValues.genBail;

    const tenant = produce(sanitizedValues, (draft) => {
      draft.status = TenantStatus.Actif.status;
      // Reset de la liste de profile si on est sur une regénération
      draft.profile_nom.profiles = [];

      profileToPush.forEach((profile) => {
        draft.profile_nom.profiles.push(profile.profile);
      });
      draft.profile_nom.nom_profile = defineProfileNom(profileToPush);
    });
    // Création de list avec les tenants qui sont candidat de ce bien. Ca permettra de convertir le tenant
    // Si plusieurs candidats tenant, permettra de supprimer leurs dossier
    const tenantsInProfiles = profileToPush.filter(
      // Si on régénère, alors forcément on veut réutiliser les profiles déjà existant
      (filtered) =>
        filtered.tenant?.id_bien === parseInt(tenant.id_bien) &&
        filtered.tenant?.status === TenantStatus.Candidat.status,
    );

    try {
      let tenantId;
      if (tenantsInProfiles.length > 0) {
        if (regenerate) {
          await createLease(tenant.id, tenant, typeEnvoi);
          tenantId = tenant.id;
        } else {
          // Ca c'est juste un début. Quand il y aura plusieurs tenants dans tenantsInPRofiles, je supprimerai les autres
          if (values.genBail === "true") {
            await createLease(
              tenantsInProfiles[0].tenant.id,
              tenant,
              typeEnvoi,
            );
          } else {
            await updateTenant(tenantsInProfiles[0].tenant.id, tenant);
          }
          tenantId = tenantsInProfiles[0].tenant.id;
        }
      } else {
        const newTenant = await addTenant(tenant);
        tenantId = newTenant.id;
        if (values.genBail === "true") {
          await createLease(newTenant.id, tenant, typeEnvoi);
        }
      }
      // Lorsqu'on regénère le bail, on ne souhaite pas regénérer la balance
      if (!regenerate) {
        await axios.post(`/tenants/${tenantId}/initial-balance`, {
          loyer_a_jour: loyer_a_jour,
        });
      }
      if (values.genBail === "true") {
        nav(
          `/bailleur/tenants/${tenantId}?tab-tenant-show=documents-courriers`,
        );
      } else {
        nav(`/bailleur/tenants/${tenantId}`);
      }
      setError("");
    } catch (error) {
      setError(handleAPIError(error));
    }
  }

  const listDiag = [
    "DPE",
    "plomb",
    "elec",
    "gaz",
    "pollution",
    "amiante",
    "rib",
  ];

  const initialProperty = properties.find(
    (property) => property.id === parseInt(propertyId),
  );

  let validationSchema = convertTenantSchema;
  if (lease.type === "mobilite") {
    validationSchema = convertTenantMobiliteSchema;
  }
  if (lease.type === "civil" || lease.type === "stockage") {
    validationSchema = convertTenantCivilSchema;
  }
  if (lease.type === "lcd") {
    validationSchema = convertTenantLCDSchema;
  }

  if (auth.home === "/pro") {
    validationSchema = validationSchema.concat(mandatairesFields);
  }
  const initialValues = toInitialValuesFromSchema(
    validationSchema,
    tenant,
    ADD_TENANT_INITIAL_VALUE,
  );

  if (!regenerate) {
    initialValues.id_bien = propertyId ?? "";
    initialValues.id_lease_template = initialProperty?.id_lease_template ?? "";
    initialValues.loyer_hc = initialProperty?.loyer_hc ?? "";
    initialValues.dernier_loyer = initialProperty?.dernier_loyer ?? "";
    initialValues.charges = initialProperty?.charges ?? "";
    initialValues.deposit = initialProperty?.deposit ?? "";
    initialValues.day_payment = initialProperty?.day_payment ?? "";
    initialValues.type_charge = initialProperty?.type_charge ?? "";
    initialValues.debut = tenant?.debut ?? "";
    initialValues.detail_charges = initialProperty?.detail_charges ?? [];
    initialValues.genBail =
      tenant?.status === TenantStatus.Candidat.status ? "true" : "";
    initialValues.id_compte_tenant = tenant?.id_compte_tenant ?? null;
    initialValues.loyer_a_jour = false;
    initialValues.type_envoi = "elec";
    initialValues.article_specific = initialProperty?.article_specific ?? "";
  } else {
    initialValues.genBail = "true";
    initialValues.type_envoi = "elec";
    initialValues.id_lease_template = initialProperty?.id_lease_template ?? "";
  }

  if (auth.home === "/pro") {
    initialValues.remuneration_type = mandataire?.remuneration_type ?? "";
    initialValues.remuneration_montant = mandataire?.remuneration_montant ?? "";
    initialValues.mandataire_tva = mandataire?.tva ?? false;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {(formikProps) => {
          async function previewBailHandler(e, values) {
            e.preventDefault();

            const errors = await formikProps.validateForm();
            if (Object.keys(errors).length > 0) {
              showFormikFieldsInError(errors, formikProps.setFieldTouched);
              return;
            }

            const sanitizedValues = { ...sanitizeValues(values) };
            delete sanitizedValues.genBail;
            sanitizedValues.id_lease_template = parseInt(
              sanitizedValues.id_lease_template,
            );

            const tenant = produce(sanitizedValues, (draft) => {
              draft.profile_nom.profiles = [];

              profileToPush.forEach((profile) => {
                draft.profile_nom.profiles.push(profile.profile);
              });
              draft.profile_nom.nom_profile = defineProfileNom(profileToPush);
            });

            try {
              const response = await axios.post(`lease_preview`, tenant);

              setPreviewBail(response.data);
              setError("");
            } catch (error) {
              setError(handleAPIError(error));
            }
          }

          const locataires = (
            <div className={"marginT20"}>
              <p className={"marginB5"}>
                Souhaitez-vous créer un bail ?
                <span className={"blueStar"}> *</span>
              </p>
              <RadioGroup styling={"button"}>
                <Radio
                  name="genBail"
                  value="false"
                  label={
                    <IconAndText
                      text={"J'ai déjà un bail, ne pas en générer"}
                      icon={<NoGenLease />}
                    />
                  }
                  className={"flex-grow"}
                />
                <Radio
                  name="genBail"
                  value="true"
                  label={
                    <IconAndText
                      text={"Générer un bail automatiquement"}
                      icon={<GenLease />}
                    />
                  }
                  className={"flex-grow"}
                />
              </RadioGroup>
              {regenerate && (
                <Card className={"marginT10 marginB10 padding5"} type={"error"}>
                  Vous pouvez utiliser cette fonction si vous avez fait une
                  erreur dans la génération initiale du bail. Pour tout
                  changement de locataire ou changement de montant du loyer,
                  veuillez passer par un avenant au bail. Rubrique locataire,
                  onglet "Actions / courriers", carte "Avenant au bail".
                </Card>
              )}
              <p className={"marginT20"}>
                Veuillez sélectionner le bien concerné
                <span className={"blueStar"}> *</span>
              </p>
              <Select
                name="id_bien"
                onChange={(e) => {
                  handleLeaseType(parseInt(e.target.value));
                  setFilesHandler(e.target.value);
                  formikProps.handleChange(e);
                }}
              >
                <option value="">Choisir le bien concerné</option>
                {properties.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.nom}
                  </option>
                ))}
              </Select>
              {formikProps.values.id_bien &&
                properties
                  .filter(
                    (filtered) =>
                      filtered.id === parseInt(formikProps.values.id_bien),
                  )
                  .map((property) => (
                    <PropertyDisplaySmallCard
                      property={property}
                      key={property.id}
                    />
                  ))}
              <p className={"marginB5 marginT20"}>
                Veuillez sélectionner le(s) locataire(s) à ajouter
                <span className={"blueStar"}> *</span>
              </p>
              <TenantAddProfile
                profileToPush={profileToPush}
                onAdd={onAddProfile}
                onDelete={onDeleteProfile}
                openAddProfileModal={openAddProfileModal}
                label={
                  <>
                    Sélectionnez ici le(s) locataire(s) que vous souhaitez
                    ajouter à votre bien. Vous pouvez choisir un profil déjà
                    présent sur Qalimo parmis vos locataires et candidats, en
                    cours ou archivés, ou créer le profil de votre locataire.
                  </>
                }
              />
            </div>
          );
          const documents = (
            <div className={"marginT10"}>
              <h2 className={"text-center"}>Documents à joindre au bail</h2>
              {lease.type !== "garage" && (
                <>
                  {!formikProps.values.dernier_loyer &&
                    propertyType === "habitation" && (
                      <Card type={"warning"} className={"padding10 marginB10"}>
                        Vous avez laissé le champ "Montant du dernier loyer
                        versé par le locataire précédent" vide. Ce champ est
                        obligatoire dès lors que ce dernier à quitté le logement
                        moins de dix-huit mois avant la signature du bail.
                      </Card>
                    )}
                  <p>
                    Il est obligatoire pour le bailleur de joindre les
                    diagnostics suivants :
                  </p>
                  <ul>
                    {propertyType === "commercial" ? (
                      <>
                        <li>
                          <span className="underline">
                            Diagnostic de performance énergétique (DPE)
                          </span>
                        </li>
                        <li>
                          <span className="underline">
                            Diagnostic d'amiante (DTA),
                          </span>{" "}
                          le cas échéant
                        </li>
                        <li>
                          <span className="underline">
                            État des risques naturels, miniers et technologiques
                            (ERNT),
                          </span>{" "}
                          le cas échéant
                        </li>
                        <li>
                          <span className="underline">
                            Annexe environnementale,
                          </span>{" "}
                          le cas échéants (pour les surfaces commerciales
                          supérieures à 2000 m²)
                        </li>
                        <li>
                          <span className="underline">
                            Annexe état récapitulatif des travaux effectués
                          </span>
                        </li>
                        <li>
                          <span className="underline">
                            Annexe état récapitulatif des travaux effectués
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <span className="underline">
                            Diagnostic de performance énergétique (DPE)
                          </span>
                        </li>
                        <li>
                          <span className="underline">
                            Constat de risque d'exposition au plomb (Crep)
                          </span>
                        </li>
                        <li>
                          <span className="underline">
                            Etat mentionnant la présence ou l'absence d'amiante
                          </span>
                        </li>
                        <li>
                          <span className="underline">
                            Diagnostic électricité
                          </span>{" "}
                          si l'installation a plus de 15 ans
                        </li>
                        <li>
                          <span className="underline">Diagnostic gaz</span> si
                          l'installation a plus de 15 ans
                        </li>
                        <li>
                          <span className="underline">
                            État des risques et pollutions (ERP)
                          </span>
                        </li>
                        <li>
                          <span className="underline">Diagnostic Bruit</span> si
                          en zone d'exposition au bruit des aéroports
                        </li>
                      </>
                    )}
                  </ul>
                  Retrouvez d’avantage d’informations dans notre{" "}
                  <Link to={"https://www.qalimo.fr/faq/"} target="_blank">
                    FAQ
                  </Link>
                </>
              )}
              <br />
              <br />
              {files?.filter((file) => listDiag.includes(file.tag)).length >
              0 ? (
                <p>
                  Voici les documents que nous avons trouvé pour votre bien. Ils
                  seront mis à disposition du locataire sur son espace Qalimo.
                </p>
              ) : (
                <Card type={"warning"} className={"padding5 marginB5"}>
                  Nous n'avons trouvé aucun document relatif à votre bien.
                </Card>
              )}
              <br />
              <p className={"marginB10"}>
                Pour modifier / compléter ces documents, rubrique{" "}
                <Link to={`/bailleur/properties/${formikProps.values.id_bien}`}>
                  Biens -> Documents
                </Link>
              </p>
              <ListFiles files={files?.filter((file) => file.shared)} />
              {formikProps.values.genBail === "true" && (
                <>
                  {regenerate && (
                    <Card
                      type={"error"}
                      className={"marginT10 padding5 marginB10"}
                    >
                      Attention : Nous ne supprimerons pas l'ancien bail généré,
                      vous devrez aller le supprimer depuis l'espace locataire,
                      onglet "Documents".
                    </Card>
                  )}
                </>
              )}
            </div>
          );
          return (
            <TabbedForm
              formikProps={formikProps}
              id="tenant-form"
              ref={formRef}
            >
              <ModalElement
                confirmCloseText={
                  "Votre formulaire n'a pas été sauvegardé. Voulez-vous vraiment quitter ?"
                }
                shouldConfirm={() => formikProps.dirty}
                size={"intermediate"}
                stickyHeader={type === TenantAddFormType.LOCATAIRE}
                blueHeader={type === TenantAddFormType.LOCATAIRE}
                onClose={() => {
                  formRef.current?.resetTab();
                }}
                header={
                  type === TenantAddFormType.LOCATAIRE && (
                    <TabbedForm.TabList>
                      <TabbedForm.Tab panelId="locataires">
                        Sélection des locataires
                      </TabbedForm.Tab>
                      <TabbedForm.Tab panelId="bail">
                        Paramètres du bail
                      </TabbedForm.Tab>
                      {formikProps.values.genBail === "true" &&
                        lease.type !== "lcd" && (
                          <TabbedForm.Tab panelId="documents">
                            Documents
                          </TabbedForm.Tab>
                        )}
                      {formikProps.values.genBail === "true" && (
                        <TabbedForm.Tab panelId="validation">
                          Envoi
                        </TabbedForm.Tab>
                      )}
                    </TabbedForm.TabList>
                  )
                }
              >
                {type === TenantAddFormType.LOCATAIRE && (
                  <Form>
                    <TabbedForm.TabPanel id="locataires">
                      {locataires}
                      <TabbedForm.Actions error={error} />
                    </TabbedForm.TabPanel>
                    <TabbedForm.TabPanel id="bail">
                      <TenantConvertForm
                        propertyType={propertyType}
                        setFilesHandler={setFilesHandler}
                        formikProps={formikProps}
                        previewBailHandler={previewBailHandler}
                        previewBail={previewBail}
                        isValid={
                          formikProps.isValid && profileToPush.length > 0
                        }
                        lease={lease}
                        updateLeaseType={updateLease}
                      />
                      <TabbedForm.Actions error={error} />
                    </TabbedForm.TabPanel>
                    <TabbedForm.TabPanel id="documents">
                      {documents}
                      <TabbedForm.Actions error={error} />
                    </TabbedForm.TabPanel>
                    {formikProps.values.genBail === "true" && (
                      <TabbedForm.TabPanel id="validation">
                        <ChooseSendFileMethod
                          values={formikProps.values}
                          propertyId={parseInt(formikProps.values.id_bien)}
                          profiles={getProfiles(profileToPush)}
                          error={error}
                        />
                      </TabbedForm.TabPanel>
                    )}
                  </Form>
                )}
                {type === TenantAddFormType.CANDIDAT && (
                  <TenantProspectForm
                    propertyId={propertyId}
                    closeTenantAddModal={closeTenantAddModal}
                  />
                )}
              </ModalElement>
            </TabbedForm>
          );
        }}
      </Formik>
    </>
  );
}
