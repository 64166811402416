import TextInput from "../../UI/TextInput";
import Select from "../../UI/Select";
import { Link } from "react-router-dom";

function HeaderPropertyForm({ profiles, isFolder }) {
  return (
    <>
      <TextInput
        type="text"
        name="nom"
        placeholder="Coloc Jeanne d'Arc"
        label={"Nom du bien"}
        mandatory
      />

      <Select
        name={"id_profile_nom_owner"}
        label="Profil bailleur a affecter"
        mandatory
      >
        <option value="">Quel profil affecter au bien ?</option>
        {/*On vérifie que le profileNom à bien des profiles de déclarés*/}
        {profiles
          ?.filter((filtered) => filtered.profiles.length !== 0)
          .map((option) => (
            <option key={option.id} value={option.id}>
              {option.nom_profile}
            </option>
          ))}
      </Select>
      <p className={"secondaryText marginB10"}>
        Si votre profil n'apparaît pas, vous pouvez le créer dans la rubrique
        <Link to={"/bailleur/profiles"}> Profils bailleur</Link>
      </p>

      {!isFolder && (
        <>
          <TextInput
            type="text"
            name="rue"
            placeholder="30 Rue Jean Moulin"
            dependon="id_parent"
            mandatory
            label="Adresse"
          />
          <TextInput
            type="text"
            name="complement_adresse"
            placeholder="Batiment A"
            dependon="id_parent"
            label="Complément d'adresse"
          />

          <div className={"flex wrap gap10"}>
            <TextInput
              type="text"
              name="cp"
              placeholder="14114"
              dependon="id_parent"
              className={"flex-grow"}
              label="Code postal"
              mandatory
            />

            <TextInput
              type="text"
              name="ville"
              placeholder="Cabourg "
              dependon="id_parent"
              label="Ville"
              mandatory
              className={"flex-grow"}
            />
          </div>
        </>
      )}
    </>
  );
}

export default HeaderPropertyForm;
