import { Form, Formik } from "formik";
import Card from "../UI/Card";
import React, { useState } from "react";
import { handleAPIError } from "../../utils";
import { toInitialValuesFromSchema } from "../../models/utils";
import { ADDRESS_INITIAL_VALUES, addressSchema } from "../../models/address";
import TextInput from "../UI/TextInput";
import FormikSubmitButton from "../UI/FormikSubmitButton";

export default function AddressForm({ onSubmit, account }) {
  const [error, setError] = useState();

  async function submitHandler(values) {
    setError("");
    try {
      await onSubmit(values);
    } catch (error) {
      setError(handleAPIError(error));
    }
  }
  const initialValues = toInitialValuesFromSchema(
    addressSchema,
    account,
    ADDRESS_INITIAL_VALUES,
  );
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={addressSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <TextInput
          name={"nom"}
          label={"Prénom / nom / société"}
          placeholder={"SCI de la colline"}
        />
        <TextInput
          name={"adresse"}
          label={"Adresse"}
          placeholder={"13 Rue de la colline"}
        />
        <TextInput
          name={"complement_adresse"}
          label={"Complément d'adresse"}
          placeholder={"Batiment B"}
        />
        <TextInput name={"cp"} label={"Code postal"} placeholder={"14000"} />
        <TextInput name={"ville"} label={"Ville"} placeholder={"Caen"} />
        <TextInput
          type="text"
          name="telephone"
          placeholder="0601323123"
          label={"Numéro de téléphone"}
        />
        <div className={"text-right"}>
          <FormikSubmitButton>Valider</FormikSubmitButton>
        </div>
        {error && (
          <Card type={"error"} className={"padding10 text-center marginT10"}>
            {error}
          </Card>
        )}
      </Form>
    </Formik>
  );
}
