import Card from "../UI/Card";
import style from "./TransactionSplitForm.module.css";
import { Croix } from "../UI/Icons";
import TextInput from "../UI/TextInput";
import Select from "../UI/Select";
import useProperties from "../../hooks/use-properties";
import BalanceTypeSelect from "../Balance/BalanceTypeSelect";
import { BalanceDomain } from "../../utils/constants/balances";
import TenantSelect from "../Tenant/TenantSelect";

export default function SplitTransacFields({
  name,
  index,
  domaineBalance,
  onDelete,
  onAffectationChange,
}) {
  const { properties } = useProperties();
  return (
    <Card className={"paddingB0 paddingT10 marginB10"}>
      <div className={style.relative}>
        <div className={`${style.close}`} onClick={onDelete}>
          <Croix />
        </div>
      </div>

      <div className={"flex gap10 wrap"}>
        <TextInput
          type="text"
          name={`${name}[${index}].comment`}
          placeholder="Commentaire"
          label="Commentaire"
          mandatory
        />
        <TextInput
          type="number"
          name={`${name}[${index}].montant`}
          placeholder="0"
          label="Montant"
          mandatory
          min={null}
          unit={"€"}
          step={"0.01"}
          size="sm"
        />
        <Select
          name={`${name}[${index}].id_bien`}
          className={"wMax-12em"}
          label={"Bien"}
          onChange={(e) =>
            onAffectationChange(`${name}[${index}]`, e, BalanceDomain.PROPERTY)
          }
        >
          <option value="">Affecter à un bien</option>
          {properties.map((option, i) => (
            <option key={i} value={option.id}>
              {option.nom}
            </option>
          ))}
        </Select>
        <TenantSelect
          name={`${name}[${index}].id_tenant`}
          className={"wMax-12em"}
          label={"Locataire"}
          onChange={(e) =>
            onAffectationChange(`${name}[${index}]`, e, BalanceDomain.TENANT)
          }
          withArchivedTenants
        />
        <BalanceTypeSelect
          name={`${name}[${index}].type`}
          className={"wMax-12em"}
          domaineBalance={domaineBalance}
        />
      </div>
    </Card>
  );
}
