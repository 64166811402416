import { array, number, object, string } from "yup";
import { dateMax, stringMax } from "./utils";
import moment from "moment";

export const courrierValidationSchema = object({
  nom: stringMax(250).required(" "),
  type: stringMax(250).required(" "),
  content: string().required(" "),
  status: number().required(" "),
  category: stringMax(250).notRequired(" "),
  text: string().required(" "),
});

export const COURRIER_INITIAL_VALUE = {
  nom: "",
  type: "",
  content: "",
  status: 0,
  text: "",
  category: "",
};

export const COURRIER_MODIF_CHARGE_INITIAL_VALUE = {
  type_edit_price: "",
  date_effet: "",
  charges: "",
  type_envoi: "elec",
  courrier_content: "",
};

export const courrierBaseSchema = object({
  type_envoi: string(),
  courrier_content: string(),
});

export const courrierModifChargeSchema = courrierBaseSchema.concat(
  object({
    type_edit_price: stringMax(250).required(" "),
    date_effet: dateMax().required(" "),
    charges: number().required(" "),
  }),
);

export const COURRIER_MODIF_LOYER_INITIAL_VALUE = {
  date_effet: "",
  loyer: "",
  type_envoi: "elec",
  courrier_content: "",
};

export const courrierModifLoyerSchema = courrierBaseSchema.concat(
  object({
    date_effet: dateMax().required(" "),
    loyer: number().required(" "),
  }),
);
export const COURRIER_CHANGEMENT_LOCATAIRE_INITIAL_VALUE = {
  date_effet: "",
  type_envoi: "elec",
  courrier_content: "",
};
export const courrierChangementLocataireSchema = courrierBaseSchema.concat(
  object({
    date_effet: dateMax().required(" "),
  }),
);
export const CONGE_LEGITIME_INITIAL_VALUE = {
  motif: "",
  type_envoi: "elec",
  courrier_content: "",
};
export const congeLegitimeSchema = courrierBaseSchema.concat(
  object({
    motif: string().required(" "),
  }),
);
export const CONGE_REPRISE_INITIAL_VALUE = {
  nom: "",
  adresse: "",
  lien_parente: "",
  type_envoi: "elec",
  courrier_content: "",
};
export const congeRepriseSchema = courrierBaseSchema.concat(
  object({
    nom: stringMax(250).required(" "),
    adresse: stringMax(250).required(" "),
    lien_parente: stringMax(250).required(" "),
  }),
);
export const CONGE_VENTE_INITIAL_VALUE = {
  prix_vente: "",
  type_envoi: "elec",
  courrier_content: "",
};
export const congeVenteSchema = courrierBaseSchema.concat(
  object({
    prix_vente: number().required(" "),
  }),
);

export const COURRIER_PROROGATION_MOBILITE_INITIAL_VALUE = {
  duree_sup: "",
  type_envoi: "elec",
  courrier_content: "",
};

export const courrierProrogationMobiliteSchema = courrierBaseSchema.concat(
  object({
    duree_sup: number()
      .min(1, "La durée ne peut être inférieur à 1 mois")
      .max(9, "La durée ne peut dépasser 9 mois")
      .required(" "),
  }),
);

export const COURRIER_VIDE_INITIAL_VALUE = {
  type_envoi: "mail",
  courrier_content: "",
};

export const REGUL_INITIAL_VALUE = {
  debut_periode: moment().subtract(1, "year").format("YYYY-MM-DD"),
  fin_periode: moment().format("YYYY-MM-DD"),
  charges_reelles: "",
  charges_payees: "",
  type_envoi: "mail",
};

export const regulSchema = courrierBaseSchema.concat(
  object({
    debut_periode: dateMax().required(" "),
    fin_periode: dateMax().required(" "),
    charges_reelles: number().required(" "),
    charges_payees: number().required(" "),
  }),
);

export const COURRIER_IRL_INITIAL_VALUE = {
  irl_last_year_annee: "",
  irl_new_annee: "",
  irl_last_year_trimestre: "",
  irl_new_trimestre: "",
  irl_last_year_indice: "",
  irl_new_indice: "",
  irl_initial_trimestre: "",
  type_envoi: "mail",
};
export const CAUTION_INITIAL_VALUE = {
  locataires: [],
  courrier_id: "caution_indeterminee",
  duree: "",
  montant_max: "",
  type_envoi: "mail",
  files: [],
};

export const cautionSchema = object({
  locataires: array().min(1, "Vous devez selectionner au moins 1 locataire"),
  montant_max: number().required(" "),
  duree: number().when("caution_type", {
    is: "determinee",
    then: (schema) =>
      schema.required(
        "La durée est obligatoire dans le cadre d'une caution de type déterminée",
      ),
    otherwise: (schema) => schema.notRequired(),
  }),
});
export const CONVOCATION_EDL_INITIAL_VALUE = {
  date_edl: "",
};
export const convocationEdlSchema = courrierBaseSchema.concat(
  object({
    date_edl: dateMax().required(" "),
  }),
);
export const RESTITUTION_DG_INITIAL_VALUE = {
  montant_reparations: "",
  has_degradations: "false",
  degradations: "",
  conserver_provision: "false",
  courrier_text: "",
};
export const restitutionDGSchema = courrierBaseSchema.concat(
  object({
    montant_reparations: number().notRequired(),
    has_degradations: stringMax(250).notRequired(),
    degradations: string().notRequired(),
    conserver_provision: stringMax(250).notRequired(),
    courrier_text: string().required(),
  }),
);

export const courrierIrlSchema = courrierBaseSchema.concat(
  object({
    irl_last_year_annee: number().required(" "),
    irl_new_annee: stringMax(250).required(" "),
    irl_last_year_trimestre: stringMax(250).required(" "),
    irl_new_trimestre: stringMax(250).required(" "),
    irl_last_year_indice: number().required(" "),
    irl_new_indice: number().required(" "),
    irl_initial_trimestre: number().required(" "),
  }),
);

export function isCourrierWithFields(courrierId) {
  return courrierId && !isCourrierWithoutFields(courrierId);
}

export function isCourrierWithoutFields(courrierId) {
  return (
    courrierId === "courrier_changement_proprietaire" ||
    courrierId === "courrier_justificatif_assurance" ||
    courrierId === "courrier_med_assurance" ||
    courrierId === "courrier_trouble_caution" ||
    courrierId === "conge_vente_meuble" ||
    courrierId === "courrier_justificatif_entretien" ||
    courrierId === "courrier_med_justificatif_entretien" ||
    courrierId === "courrier_impaye" ||
    courrierId === "courrier_caution_impaye" ||
    courrierId === "courrier_med_impaye" ||
    courrierId === "courrier_med_caution_impaye" ||
    courrierId === "avenant_libre" ||
    courrierId === "rappel_fin_bail" ||
    courrierId === "recu_dg"
  );
}
