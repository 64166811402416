import { classnames } from "../../utils";
import { Table } from "../UI/Table";
import moment from "moment";
import {
  BalanceDomain,
  BalanceType,
  BalanceTypeCategory,
} from "../../utils/constants/balances";
import { currencyFormatter } from "../../api/Functions";
import { DotsAction, Edit, Interrogation, Quittance } from "../UI/Icons";
import useMediaQuery from "../../hooks/use-media-query";
import Card from "../UI/Card";
import Tooltip from "../UI/Tooltip";
import Dropdown from "../UI/Dropdown";
import DocumentDownloadLink from "../UI/DocumentDownloadLink";
import { useBalanceTypes } from "../../hooks/use-balances";
import { DocType } from "../../utils/constants/documents";

export default function BalanceList({
  balances,
  quittances,
  isTenant,
  isLocataire,
  onUpdate,
  onCreate,
}) {
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const { balanceTypes, balanceTypesByCategory, isLoading } = useBalanceTypes();
  if (isLoading) return null;
  let totalDebit = 0;
  let totalCredit = 0;

  const balanceTotal = balances.reduce(
    (accumulator, balance) => accumulator + balance.montant,
    0,
  );
  const rows = [...balances]
    .reverse()
    .map((balance, i) => {
      if (
        balance.type !== BalanceType.APPEL_DG &&
        balance.type !== BalanceType.REMBOURSEMENT_DG &&
        balance.type !== BalanceType.APPEL_RESTITUTION_DG &&
        balance.type !== BalanceType.PAIEMENT_DG
      ) {
        if (balance.montant <= 0) {
          totalDebit += balance.montant;
        } else {
          totalCredit += balance.montant;
        }
      }

      const quittance = quittances?.find((quittance) =>
        moment(quittance.meta.date_quittance, "MMM-YYYY").isSame(
          balance.date_transac,
          "month",
        ),
      );

      async function markAsPaid(date_transac) {
        try {
          const appelStaticIds = balanceTypesByCategory[
            BalanceTypeCategory.LOCATAIRE_APPEL
          ].map((balanceType) => balanceType.static_id);
          await onCreate({
            montant: balances
              .filter(
                (balance) =>
                  moment(balance.date_transac).isSame(date_transac, "month") &&
                  appelStaticIds.includes(balance.type),
              )
              .reduce((accumulator, current) => {
                return accumulator - current.montant;
              }, 0),
            type: BalanceType.PAIEMENT,
            domaine: BalanceDomain.TENANT,
            date_transac: date_transac,
          });
        } catch (error) {
          console.error(error);
        }
      }

      return (
        <Table.Row key={i}>
          <Table.Cell className="secondaryText">
            {moment(balance.date_transac).format("DD/MM/YYYY")}
          </Table.Cell>
          <Table.Cell
            className={classnames(
              "secondaryText",
              balance.montant < 0 ? "blue" : "green",
            )}
          >
            {balanceTypes[balance.type].text}
            {(balance.type === BalanceType.APPEL_LOYER ||
              balance.type === BalanceType.APPEL_CHARGE) &&
              " " + moment(balance.date_transac).format("MMMM")}
            {(balance.comment || balance.description) && (
              <Tooltip
                content={balance.comment || balance.description}
                direction="bottom"
              >
                <Interrogation className={"marginL5"} />
              </Tooltip>
            )}
          </Table.Cell>
          {isSmallScreen ? (
            <Table.Cell className="secondaryText blue">
              {currencyFormatter(balance.montant)}
            </Table.Cell>
          ) : (
            <>
              <Table.Cell align="right" className="secondaryText blue">
                {balance.montant < 0 && currencyFormatter(balance.montant)}
              </Table.Cell>
              <Table.Cell align="right" className="secondaryText green">
                {balance.montant > 0 &&
                  `+${currencyFormatter(balance.montant)}`}
              </Table.Cell>
              {isTenant && (
                <Table.Cell align="right" className="secondaryText">
                  {currencyFormatter(totalCredit + totalDebit)}
                </Table.Cell>
              )}
            </>
          )}
          <Table.Cell className="secondaryText">
            <div className={"flex"}>
              {!isLocataire &&
                (isTenant && balance.type === BalanceType.APPEL_LOYER ? (
                  <Dropdown
                    header={<DotsAction />}
                    type={"click"}
                    body={
                      <>
                        <div
                          onClick={() => onUpdate(balance)}
                          className={"secondaryText"}
                        >
                          Editer
                        </div>
                        <div
                          onClick={async () => {
                            await markAsPaid(balance.date_transac);
                          }}
                          className={"secondaryText"}
                        >
                          Marquer comme payé
                        </div>
                      </>
                    }
                  />
                ) : (
                  <span
                    className="cursorPointer"
                    onClick={() => onUpdate(balance)}
                  >
                    <Edit />
                  </span>
                ))}
              {isTenant &&
                balance.type === BalanceType.APPEL_LOYER &&
                quittance && (
                  <DocumentDownloadLink
                    className={"marginL5"}
                    documentId={quittance.id}
                    file={quittance}
                    save
                  >
                    <Quittance stroke={"#9D9D9D"} size={18} />
                  </DocumentDownloadLink>
                )}
              {balance.file && (
                <DocumentDownloadLink
                  className={"marginL5"}
                  documentId={balance.id}
                  file={balance.file}
                  doctype={DocType.JUSTIFICATIF_BALANCE}
                >
                  <Quittance stroke={"#9D9D9D"} size={18} />
                </DocumentDownloadLink>
              )}
            </div>
          </Table.Cell>
        </Table.Row>
      );
    })
    .reverse();

  return (
    <>
      <Card className={classnames("w-100")}>
        <div
          className={
            "flex wrap gap10 space-between align-items-center marginB20"
          }
        >
          <p className={"cardTitle"}>Balance</p>
        </div>
        <Table>
          <Table.Header>
            <Table.Column>Date</Table.Column>
            <Table.Column>Nature de l'opération</Table.Column>

            {isSmallScreen ? (
              <Table.Column>Montant</Table.Column>
            ) : (
              <>
                <Table.Column align="right">Débit</Table.Column>
                <Table.Column align="right">Crédit</Table.Column>
                {isTenant && <Table.Column align="right">Solde</Table.Column>}
              </>
            )}
            <Table.Column align="right"></Table.Column>
          </Table.Header>
          <Table.Body>
            <Table.Row className={"primaryText"}>
              <Table.Cell></Table.Cell>
              <Table.Cell>TOTAL</Table.Cell>
              {isSmallScreen ? (
                <Table.Cell colSpan={"2"}>
                  {currencyFormatter(totalCredit)}
                </Table.Cell>
              ) : (
                <>
                  <Table.Cell align="right">
                    {currencyFormatter(totalDebit)}
                  </Table.Cell>
                  <Table.Cell align="right">
                    +{currencyFormatter(totalCredit)}
                  </Table.Cell>
                  {isTenant && (
                    <Table.Cell align="right">
                      {currencyFormatter(balanceTotal) ?? "0€"}
                    </Table.Cell>
                  )}
                </>
              )}
              <Table.Cell></Table.Cell>
            </Table.Row>
            {rows}
          </Table.Body>
        </Table>
      </Card>
    </>
  );
}
