import SignatureStatusMark from "../ElectronicSigning/SignatureStatusMark";
import React, { useState } from "react";
import ContractStatusIndicator from "../ElectronicSigning/ContractStatusIndicator";
import Button from "../UI/Button";
import {
  SignatureContratStatus,
  SignatureStatus,
} from "../../models/signatureElectronique";

export default function Oodrive({ file, onCancelSigning, onRelance }) {
  const [relance, setRelance] = useState([]);
  return (
    <>
      <ContractStatusIndicator file={file} />
      <p className={"primaryText marginB5 marginT10"}>Signataires : </p>
      <div className={"grid gap10"}>
        {file?.signataires?.map((signataire, i) => (
          <div key={i} className={"flex align-items-center space-between"}>
            {signataire.prenom} {signataire.nom}{" "}
            {signataire.status === SignatureStatus.Signed.status ? (
              <SignatureStatusMark status={signataire.status} />
            ) : relance?.includes(signataire.id) ? (
              "Relancé"
            ) : (
              <Button
                onClick={() => {
                  onRelance(file.id, signataire.id);
                  setRelance((prevRelance) => [...prevRelance, signataire.id]);
                }}
              >
                Relancer
              </Button>
            )}
          </div>
        ))}
      </div>
      {file?.signature_electronique_status !==
        SignatureContratStatus.Archived.status && (
        <div className={"text-right marginT20"}>
          <Button
            buttonStyle={"secondaryDelete"}
            onClick={() => onCancelSigning(file.id)}
          >
            Annuler la signature
          </Button>
        </div>
      )}
    </>
  );
}
