import { useEffect, useRef, useState } from "react";

import { Form, Formik } from "formik";
import TabbedForm from "../../UI/TabbedForm";
import SelectCourrier from "./SelectCourrier";
import CourrierFields from "./CourrierFields";
import { sanitizeValues } from "../../../models/utils";
import { handleAPIError, showFormikFieldsInError } from "../../../utils";
import ChooseSendFileMethod from "../../UI/ChooseSendFileMethod";
import {
  CONGE_LEGITIME_INITIAL_VALUE,
  CONGE_REPRISE_INITIAL_VALUE,
  CONGE_VENTE_INITIAL_VALUE,
  congeLegitimeSchema,
  congeRepriseSchema,
  congeVenteSchema,
  CONVOCATION_EDL_INITIAL_VALUE,
  convocationEdlSchema,
  COURRIER_CHANGEMENT_LOCATAIRE_INITIAL_VALUE,
  COURRIER_IRL_INITIAL_VALUE,
  COURRIER_MODIF_CHARGE_INITIAL_VALUE,
  COURRIER_MODIF_LOYER_INITIAL_VALUE,
  COURRIER_PROROGATION_MOBILITE_INITIAL_VALUE,
  COURRIER_VIDE_INITIAL_VALUE,
  courrierBaseSchema,
  courrierChangementLocataireSchema,
  courrierIrlSchema,
  courrierModifChargeSchema,
  courrierModifLoyerSchema,
  courrierProrogationMobiliteSchema,
  isCourrierWithoutFields,
  REGUL_INITIAL_VALUE,
  regulSchema,
  RESTITUTION_DG_INITIAL_VALUE,
  restitutionDGSchema,
} from "../../../models/courrier";
import { useNavigate } from "react-router-dom";
import Card from "../../UI/Card";

export default function AvenantForm({
  Modal,
  tenant,
  onSubmit,
  closeModal,
  type,
  openAddProfileModal,
  profiles,
  onAddProfile,
  onDeleteProfile,
  balances,
  shortcut,
  resetShortcut,
  property,
}) {
  const [courrierId, setCourrierId] = useState("");
  const [error, setError] = useState("");
  const [editorHTML, setEditorHTML] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const nav = useNavigate();
  const formRef = useRef();

  const tenantId = tenant?.id;

  useEffect(() => {
    (async function () {
      if (tenantId && isCourrierWithoutFields(courrierId)) {
        setEditorHTML("");
        setIsGenerating(true);
        setError("");
        try {
          const documentHTML = await onSubmit(
            tenantId,
            { courrier_id: courrierId, courrier_content: "" },
            true,
          );
          setEditorHTML(documentHTML);
        } catch (e) {
          setError(handleAPIError(e));
        }
        setIsGenerating(false);
      }
    })();
  }, [courrierId, tenantId, onSubmit]);
  useEffect(() => {
    if (shortcut) {
      setCourrierId(shortcut);
      formRef.current.selectTab("form");
    }
  }, [shortcut]);

  async function submitHandler(
    values,
    { resetForm, setSubmitting },
    preview = false,
  ) {
    setSubmitting(true);
    const sanitizedValues = { ...sanitizeValues(values) };
    sanitizedValues.courrier_id = courrierId;
    sanitizedValues.courrier_content = editorHTML;
    if (courrierId === "changement_locataire" && profiles) {
      sanitizedValues.profiles = profiles;
    }
    setError("");
    try {
      if (preview === true) {
        const documentHTML = await onSubmit(tenant.id, sanitizedValues, true);
        setEditorHTML(documentHTML);
      } else {
        await onSubmit(tenant.id, sanitizedValues);
        resetForm();
        setCourrierId("");
        setEditorHTML("");
        resetShortcut();
        closeModal();
        nav(
          `/bailleur/tenants/${tenant.id}?tab-tenant-show=documents-courriers`,
        );
      }
    } catch (error) {
      setError(handleAPIError(error));
    }
    setSubmitting(false);
  }
  let photo;
  switch (type) {
    case "conge":
      photo = "/photo_conge.png";
      break;
    case "avenant":
      photo = "/photo_avenant.png";
      break;
    default:
      photo = "/photo_envoyer_courrier.png";
  }
  let initialValues, validationSchema;
  switch (courrierId) {
    case "modif_charge":
      initialValues = COURRIER_MODIF_CHARGE_INITIAL_VALUE;
      validationSchema = courrierModifChargeSchema;
      break;
    case "modif_loyer":
      initialValues = COURRIER_MODIF_LOYER_INITIAL_VALUE;
      validationSchema = courrierModifLoyerSchema;
      break;
    case "prorogation_mobilite":
      initialValues = COURRIER_PROROGATION_MOBILITE_INITIAL_VALUE;
      validationSchema = courrierProrogationMobiliteSchema;
      break;
    case "changement_locataire":
      initialValues = COURRIER_CHANGEMENT_LOCATAIRE_INITIAL_VALUE;
      validationSchema = courrierChangementLocataireSchema;
      break;
    case "conge_legitime":
      initialValues = CONGE_LEGITIME_INITIAL_VALUE;
      validationSchema = congeLegitimeSchema;
      break;
    case "conge_reprise_nu":
    case "conge_reprise_meuble":
      initialValues = CONGE_REPRISE_INITIAL_VALUE;
      validationSchema = congeRepriseSchema;
      break;
    case "conge_vente_nu":
      initialValues = CONGE_VENTE_INITIAL_VALUE;
      validationSchema = congeVenteSchema;
      break;
    case "regul_charge":
      initialValues = REGUL_INITIAL_VALUE;
      validationSchema = regulSchema;
      break;
    case "courrier_irl":
      initialValues = COURRIER_IRL_INITIAL_VALUE;
      validationSchema = courrierIrlSchema;
      break;
    case "convocation_edl":
      initialValues = CONVOCATION_EDL_INITIAL_VALUE;
      validationSchema = convocationEdlSchema;
      break;
    case "restitution_dg":
      initialValues = RESTITUTION_DG_INITIAL_VALUE;
      validationSchema = restitutionDGSchema;
      break;
    case "courrier_changement_proprietaire":
    case "courrier_justificatif_assurance":
    case "courrier_med_assurance":
    case "courrier_trouble":
    case "courrier_trouble_caution":
    case "courrier_med_trouble":
    case "conge_vente_meuble":
    case "courrier_justificatif_entretien":
    case "courrier_med_justificatif_entretien":
    case "courrier_impaye":
    case "courrier_caution_impaye":
    case "courrier_med_impaye":
    case "courrier_med_caution_impaye":
    case "avenant_libre":
    case "rappel_fin_bail":
    case "recu_dg":
      initialValues = COURRIER_VIDE_INITIAL_VALUE;
      validationSchema = courrierBaseSchema;
      break;
    default:
      initialValues = {};
      validationSchema = null;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          let tabIndex = 0;
          return (
            <>
              <TabbedForm
                draftType={2}
                formikProps={formikProps}
                id="avenant-form"
                ref={formRef}
              >
                <Modal
                  stickyHeader
                  blueHeader
                  size={"big"}
                  onCancel={() => {
                    setEditorHTML("");
                    setError("");
                    setCourrierId("");
                    resetShortcut();
                    formRef.current?.resetTab();
                  }}
                  header={
                    <>
                      <img
                        src={photo}
                        alt="Finance config"
                        className={"center marginB10"}
                      />
                      <TabbedForm.TabList>
                        <TabbedForm.Tab
                          panelId="type-avenant"
                          index={tabIndex++}
                        >
                          {type === "avenant" && "Type d'avenant"}
                          {type === "courrier" && "Type de courrier"}
                          {type === "conge" && "Type de congé"}
                        </TabbedForm.Tab>

                        {Object.keys(initialValues).length > 0 && (
                          <>
                            <TabbedForm.Tab panelId="form" index={tabIndex++}>
                              Champs à modifier
                            </TabbedForm.Tab>
                            <TabbedForm.Tab
                              panelId="document"
                              index={tabIndex++}
                            >
                              Validation & envoi
                            </TabbedForm.Tab>
                          </>
                        )}
                      </TabbedForm.TabList>
                    </>
                  }
                >
                  <Form>
                    <div className="marginT10">
                      <TabbedForm.TabPanel id="type-avenant">
                        <SelectCourrier
                          onSelectCourrier={setCourrierId}
                          tenant={tenant}
                          type={type}
                        />
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="form">
                        <CourrierFields
                          balances={balances}
                          property={property}
                          courrierId={courrierId}
                          tenant={tenant}
                          content={editorHTML}
                          isSubmitting={
                            formikProps.isSubmitting || isGenerating
                          }
                          onContentChange={setEditorHTML}
                          openAddProfileModal={openAddProfileModal}
                          profiles={profiles}
                          onAddProfile={onAddProfile}
                          onDeleteProfile={onDeleteProfile}
                          onPreview={async () => {
                            const errors = await formikProps.validateForm();
                            if (Object.keys(errors).length === 0) {
                              setEditorHTML("");
                              return submitHandler(
                                formikProps.values,
                                formikProps,
                                true,
                              );
                            }
                            showFormikFieldsInError(
                              errors,
                              formikProps.setFieldTouched,
                            );
                          }}
                        />
                        {error && (
                          <Card
                            type={"error"}
                            className={"padding10 text-center marginT10"}
                          >
                            {error}
                          </Card>
                        )}
                      </TabbedForm.TabPanel>
                      <TabbedForm.TabPanel id="document">
                        <ChooseSendFileMethod
                          values={formikProps.values}
                          propertyId={tenant?.id_bien}
                          profiles={profiles}
                        />
                        {error && (
                          <Card
                            type={"error"}
                            className={"padding10 text-center marginT10"}
                          >
                            {error}
                          </Card>
                        )}
                      </TabbedForm.TabPanel>
                    </div>
                  </Form>
                </Modal>
              </TabbedForm>
            </>
          );
        }}
      </Formik>
    </>
  );
}
