export class BalanceType {
  static APPEL_LOYER = "appel_loyer";
  static APPEL_DG = "appel_dg";
  static APPEL_CHARGE = "appel_charge";
  static PAIEMENT_DG = "paiement_dg";
  static REMBOURSEMENT_DG = "remboursement_dg";
  static APPEL_RESTITUTION_DG = "appel_restitution_dg";
  static PAIEMENT = "paiement";
}

export class BalanceTypeCategory {
  static CHARGE = "charge";
  static TRAVAUX = "travaux";
  static LOCATAIRE_APPEL = "locataire_appel";
  static LOCATAIRE_PAIEMENT = "locataire_paiement";
  static LOCATAIRE_REMBOURSEMENT = "locataire_remboursement";
}

export class BalanceDomain {
  static TENANT = "tenant";
  static PROPERTY = "property";
  static FOLDER = "folder";
}
