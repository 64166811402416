import useResources from "./use-resources";
import useAuthenticatedAxios from "./use-authenticated-axios";
import { useMemo } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { BalanceDomain } from "../utils/constants/balances";
import moment from "moment";

export function useTenantBalances(tenantId, isLocataire) {
  return useBalances(BalanceDomain.TENANT, tenantId, {
    params: { is_locataire: isLocataire },
  });
}

export function useAllTenantsBalances(isLocataire) {
  const { resources: balances } = useResources("balance_tenants/balance", {
    params: { is_locataire: isLocataire },
  });
  return {
    balances,
  };
}

export function useTenantsBalanceQuittance(monthsFromNow) {
  const axios = useAuthenticatedAxios();
  const fallbackData = useMemo(() => {
    return {};
  }, []);
  const { data, error, isLoading } = useSWR(
    ["balance_tenants/quittance", monthsFromNow],
    ([url, monthsFromNow]) => {
      return axios
        .get(url, { params: { months_from_now: monthsFromNow } })
        .then((res) => res.data);
    },
    { fallbackData },
  );

  return {
    balanceQuittance: data.balances_quittances || fallbackData,
    monthsFromNow: data.months_from_now || 0,
    error,
    isLoading,
  };
}

export default function useBalances(domain, parentId, options = {}) {
  function getRootUrl(domain, parentId) {
    let rootUrl = "";
    switch (domain) {
      case BalanceDomain.TENANT:
        rootUrl = `tenants`;
        break;
      case BalanceDomain.PROPERTY:
        rootUrl = `biens`;
        break;
      case BalanceDomain.FOLDER:
        rootUrl = `property-folders`;
        break;
      default:
        throw new Error(`Unknown domain: ${domain}`);
    }
    return parentId ? `${rootUrl}/${parentId}/balances` : null;
  }

  const {
    deleteBalance: rawDeleteBalance,
    updateBalance: rawUpdateBalance,
    createBalance: rawCreateBalance,
    ...rest
  } = useRawBalances(getRootUrl(domain, parentId), options);

  function deleteBalance(id, domain, parentId) {
    return rawDeleteBalance(id, {
      alternateRootPath:
        domain && parentId ? getRootUrl(domain, parentId) : undefined,
    });
  }

  function updateBalance(id, balance, domain, parentId) {
    return rawUpdateBalance(id, balance, {
      sort: (a, b) => moment(b.date_transac) - moment(a.date_transac),
      alternateRootPath:
        domain && parentId ? getRootUrl(domain, parentId) : undefined,
    });
  }

  function createBalance(balance, domain, parentId) {
    return rawCreateBalance(balance, {
      sort: (a, b) => moment(b.date_transac) - moment(a.date_transac),
      alternateRootPath:
        domain && parentId ? getRootUrl(domain, parentId) : undefined,
    });
  }

  return {
    deleteBalance,
    updateBalance,
    createBalance,
    ...rest,
  };
}

export function useRawBalances(url, options = {}) {
  const {
    resources: balances,
    error,
    isLoading,
    mutate,
    axios,
    del: deleteBalance,
    update: updateBalance,
    create: createBalance,
  } = useResources(url, options);

  function batchUpdateBalances(balances) {
    return mutate(
      async () => {
        const res = await axios.put(`${url}/batch-update`, balances);
        return res.data;
      },
      { revalidate: false },
    );
  }

  return {
    balances,
    error,
    isLoading,
    deleteBalance,
    updateBalance,
    createBalance,
    batchUpdateBalances,
    mutate,
    refreshBalances: mutate,
  };
}

export function useBalanceTypes() {
  const axios = useAuthenticatedAxios();

  const {
    data: balanceTypes,
    error,
    isLoading,
  } = useSWRImmutable("balance-types", (url) => {
    return axios.get(url).then((res) => res.data);
  });

  const balanceTypesList = balanceTypes ? Object.values(balanceTypes) : [];

  const balanceTypesByCategory = Object.fromEntries(
    [
      ...new Set(balanceTypesList.map((balanceType) => balanceType.category)),
    ].map((category) => [
      category,
      balanceTypesList.filter(
        (balanceType) => balanceType.category === category,
      ),
    ]),
  );
  return {
    balanceTypes,
    balanceTypesByCategory,
    error,
    isLoading,
  };
}
