import Card from "../UI/Card";
import { Argent, CalendarCheck } from "../UI/Icons";
import BalanceForm from "../Balance/BalanceForm";
import { useModal } from "../../hooks/use-modal";
import { useState } from "react";
import moment from "moment/moment";
import { classnames, setBalanceAmountSignFromType } from "../../utils";
import { currencyFormatter } from "../../api/Functions";
import useBalances, { useBalanceTypes } from "../../hooks/use-balances";
import IconAndText from "../UI/IconAndText";
import SelectStandalone from "../UI/SelectStandalone";
import BalanceActionsCard from "../Balance/BalanceActionsCard";
import BalanceList from "../Balance/BalanceList";
import { sanitizeValues } from "../../models/utils";
import { getBalanceParentId } from "../../utils/balances";

export default function ComptaShow({ parentId, domaineBalance }) {
  const { balanceTypesByCategory, isLoading } = useBalanceTypes();
  const {
    balances,
    createBalance,
    updateBalance,
    deleteBalance,
    batchUpdateBalances,
  } = useBalances(domaineBalance, parentId);
  const [BalanceModal, closeBalanceModal, openBalanceModal] = useModal();
  const [balanceToEdit, setBalanceToEdit] = useState();
  const [balanceFilter, setBalanceFilter] = useState("all");
  if (isLoading) return null;

  const years = balances.map((balance) =>
    new Date(balance.date_transac).getFullYear(),
  );
  const uniqueYears = [...new Set(years)].sort((a, b) => b - a);

  function filteringFunc(balance) {
    return moment(balance.date_transac).isSame(
      `${balanceFilter}-01-01`,
      "year",
    );
  }

  const balancesFiltered =
    balanceFilter === "all" ? balances : balances.filter(filteringFunc);
  const balancesExcluded =
    balanceFilter === "all"
      ? []
      : balances.filter((balance) => !filteringFunc(balance));

  const balanceTotal = balancesFiltered.reduce(
    (accumulator, balance) => accumulator + balance.montant,
    0,
  );

  async function submitBalanceHandler(edit, values) {
    const sanitizedValues = { ...sanitizeValues(values) };
    // change to negative if type is Appel .*
    setBalanceAmountSignFromType(sanitizedValues, balanceTypesByCategory);

    if (edit) {
      await updateBalance(
        balanceToEdit.id,
        sanitizedValues,
        balanceToEdit.domaine,
        getBalanceParentId(balanceToEdit),
      );
    } else {
      // Don't allow creating a balance with a different domaine for now
      sanitizedValues.domaine = domaineBalance;
      await createBalance(sanitizedValues);
    }
    setBalanceToEdit(null);
    closeBalanceModal();
  }

  return (
    <>
      <BalanceForm
        balance={balanceToEdit}
        ModalElement={BalanceModal}
        domaineBalance={balanceToEdit ? balanceToEdit.domaine : domaineBalance}
        onClose={() => setBalanceToEdit(null)}
        onSubmit={(values) =>
          submitBalanceHandler(balanceToEdit !== undefined, values)
        }
        onDelete={async (id, domaine, parentId) => {
          try {
            await deleteBalance(id, domaine, parentId);
            closeBalanceModal();
          } catch (error) {
            console.error(error);
          }
        }}
      />
      <div className={"flex wrap-reverse gap10 marginB10"}>
        <Card className={"flex-grow flex padding10 align-items-center"}>
          <div className={"center text-center"}>
            <IconAndText
              icon={<Argent />}
              text={<p className={"primaryText"}>Solde comptable du bien</p>}
            />
            <p
              className={classnames(
                "primaryText",
                balanceTotal < 0 ? "error" : "green",
              )}
            >
              {currencyFormatter(balanceTotal) ?? "0€"}
            </p>
          </div>
        </Card>
        <Card className={"flex-grow flex padding10 align-items-center"}>
          <IconAndText
            icon={<CalendarCheck stroke={"#707989"} />}
            text={
              <>
                <p className={"primaryText"}>Année de l'exercice comptable</p>
                <SelectStandalone
                  onChange={(e) => setBalanceFilter(e.target.value)}
                >
                  <option value={"all"}>Tous</option>
                  {uniqueYears.map((year, i) => (
                    <option value={year} key={i}>
                      {year}
                    </option>
                  ))}
                </SelectStandalone>
              </>
            }
          />
        </Card>
        <BalanceActionsCard
          balances={balancesFiltered}
          onBatchUpdate={(newBalances) =>
            batchUpdateBalances([...newBalances, ...balancesExcluded])
          }
          onAddBalance={() => {
            setBalanceToEdit(undefined);
            openBalanceModal();
          }}
          domaineBalance={domaineBalance}
        />
      </div>
      <BalanceList
        balances={balancesFiltered}
        domaineBalance={domaineBalance}
        onUpdate={(balance) => {
          setBalanceToEdit(balance);
          openBalanceModal();
        }}
      />
    </>
  );
}
