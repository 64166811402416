import { FieldArray, Form, Formik } from "formik";
import Button from "../UI/Button";
import { toInitialValuesRecursive } from "../../models/utils";
import SplitTransacFields from "../Transaction/SplitTransacFields";
import { TRANSACTION_CHILD_INITIAL_VALUE } from "../../models/transactionChild";
import Card from "../UI/Card";
import { PlusBank } from "../UI/Icons";
import TextInput from "../UI/TextInput";
import {
  FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  financeDecoupeValidationSchema,
} from "../../models/financeDecoupe";
import FormikSubmitButton from "../UI/FormikSubmitButton";
import Select from "../UI/Select";
import useProfileNoms from "../../hooks/use-profile-noms";
import { BalanceDomain } from "../../utils/constants/balances";

function FinanceConfigDecoupeForm({ config, onSubmit, onDelete }) {
  const { profileNoms } = useProfileNoms();
  const initialValues = toInitialValuesRecursive(
    config,
    FINANCE_DECOUPE_FORM_INITIAL_VALUE,
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={financeDecoupeValidationSchema}
    >
      {({ values, setFieldValue, handleChange }) => {
        return (
          <Form>
            <h2 className={"margin0"}>Template de découpe de transaction</h2>

            <TextInput
              type={"text"}
              label={"Nom du template"}
              name={"nom"}
              className={"marginT10"}
              mandatory
            />
            <Select name="id_profile_nom">
              <option value="">Affecter à un profil bailleur</option>
              {profileNoms.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.nom_profile}
                </option>
              ))}
            </Select>
            <FieldArray
              name="splits"
              render={(arrayHelpers) => (
                <>
                  {values?.splits?.map((child, index) => (
                    <SplitTransacFields
                      onDelete={() => arrayHelpers.remove(index)}
                      onAffectationChange={(prefix, e, domaineBalance) => {
                        if (domaineBalance !== child.domaine) {
                          let fieldToReset;
                          if (domaineBalance === BalanceDomain.TENANT) {
                            fieldToReset = `${prefix}.id_bien`;
                          } else if (
                            domaineBalance === BalanceDomain.PROPERTY
                          ) {
                            fieldToReset = `${prefix}.id_tenant`;
                          }
                          setFieldValue(fieldToReset, "");
                          setFieldValue(`${prefix}.type`, "");
                          setFieldValue(`${prefix}.domaine`, domaineBalance);
                        }
                        handleChange(e);
                      }}
                      name={"splits"}
                      child={child}
                      index={index}
                      domaineBalance={child.domaine}
                      key={index}
                    />
                  ))}
                  <Card
                    className={`flex justify-content-center align-items-center paddingB10 paddingT10 marginB10`}
                    onClick={() =>
                      arrayHelpers.push(TRANSACTION_CHILD_INITIAL_VALUE)
                    }
                  >
                    <PlusBank />
                    <p className={"secondaryText marginL10"}>
                      Ajouter une entrée
                    </p>
                  </Card>
                </>
              )}
            />
            <div className={"text-right"}>
              <Button
                type="button"
                buttonStyle={"secondaryDelete"}
                className={"marginR10"}
                onClick={() => onDelete(config.id)}
              >
                Supprimer
              </Button>
              <FormikSubmitButton>Valider</FormikSubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FinanceConfigDecoupeForm;
